import { CustomerOrderType } from 'types/common'
import { SET_CUSTOMER_ORDER, CustomerOrderActionTypes } from '../actions/customerOrder'

export interface CustomerOrderState {
  salesOrderId: string | null
  orderType: CustomerOrderType
  email: string | undefined
}

const initialState: CustomerOrderState = {
  salesOrderId: null,
  orderType: null,
  email: undefined,
}

export default (state = initialState, action: CustomerOrderActionTypes) => {
  const { payload } = action

  switch (action.type) {
    case SET_CUSTOMER_ORDER: {
      return {
        salesOrderId: payload.salesOrderId,
        orderType: payload.orderType,
        email: payload.email,
      }
    }

    default:
      return state
  }
}
