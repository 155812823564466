/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/camelcase */

import { useStoreData } from 'data'
import {
  formatCartItems,
  formatCommonData,
  formatFrameAdvisorBaseData,
  formatFrameAdvisorPageData,
  formatProducts,
  formatSessionId,
  pushToDataTrack,
  sentAnalitycsError,
  useLoadAnalyticsScript,
} from '@abstract/ss-adobe-analytics'
import { executeOnce, saveAnalyticsTealiumSended, useRefValue } from '@abstract/core'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CartItem as CartItemType } from 'types/cart'
import { CustomProduct } from 'types/product'
import { Store } from 'types/store'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useFrameAdvisorVideoProfile } from 'hooks/useFrameAdvisorVideoProfile'
import {
  AnalitycsEventFunction,
  AnalyticsConfig,
  AnalyticsEventDataLayer,
  AnalyticsEventPayload,
  ErrorData,
} from '@abstract/ss-adobe-analytics/dist/types/types'
import config from 'config'

export const AnalyticsContext = React.createContext({
  sendAnalyticsEvent: (
    _payload: AnalyticsEventPayload,
    _addCommonData = false,
    _isEventWithCatalogProducts = false
  ) => {},
})

export const AnalyticsProvider: React.FC<{ analyticsConfig: AnalyticsConfig }> = ({
  analyticsConfig,
  children,
}) => {
  const frameAdvisorState = useSelector(state => state.frameAdvisor)
  const sessionId = useSelector(state => state.session.token)

  const { data: storeData } = useStoreData()
  const { data: videoProfileData } = useFrameAdvisorVideoProfile()

  const store = storeData?.store

  const storeRef = useRefValue(storeData?.store)
  const faStateRef = useRefValue(frameAdvisorState)
  const faProfileRef = useRefValue(videoProfileData?.frameAdvisorFsaVideoProfile)

  useLoadAnalyticsScript(analyticsConfig, frameAdvisorState, sessionId, store, config.version || '')

  const sendAnalyticsEvent = useCallback(
    (payload: AnalyticsEventPayload, addCommonData = false, isEventWithCatalogProducts = false) => {
      // We need to wait one tick to generate the object as we must
      // wait for all ref to be updated on some edge cases
      setTimeout(() => {
        const store = storeRef.current
        if (!analyticsConfig.isEnabled || !store) return

        const newDataLayer: AnalyticsEventDataLayer = addCommonData
          ? {
              ...formatSessionId(sessionId),
              ...formatCommonData(
                analyticsConfig,
                store,
                config.version || '',
                isEventWithCatalogProducts,
                payload
              ),
              ...formatFrameAdvisorBaseData(faStateRef.current),
              ...formatFrameAdvisorPageData(faStateRef.current, faProfileRef.current),
              ...payload,
            }
          : {
              ...formatSessionId(sessionId),
              ...payload,
            }
        pushToDataTrack(newDataLayer)
      }, 0)
    },
    [analyticsConfig, faProfileRef, faStateRef, sessionId, storeRef]
  )

  // we need to memoize the context value to avoid sending
  // multiple events in useAnalyticsEvent
  const analyticsContextValue = useMemo(() => ({ sendAnalyticsEvent }), [sendAnalyticsEvent])

  return (
    <AnalyticsContext.Provider value={analyticsContextValue}>{children}</AnalyticsContext.Provider>
  )
}

/** Provide Common Data in sendEvent Callback Payload
 * @example
 * const sendTealiumNewSession = useSendAnalyticsEvent({ id: 'Start-Session' })
 * sendTealiumNewSession()
 */
export const useSendAnalyticsEvent = <T extends unknown[]>(
  payload: AnalyticsEventPayload | AnalitycsEventFunction<T>,
  addCommonData = false,
  isEventWithCatalogProducts = false
) => {
  const { sendAnalyticsEvent } = useContext(AnalyticsContext)

  const payloadRef = useRefValue(payload)

  return useCallback(
    (...args: T) => {
      const currentPayload = payloadRef.current
      if (typeof currentPayload === 'function') {
        return sendAnalyticsEvent(
          currentPayload(...args),
          addCommonData,
          isEventWithCatalogProducts
        )
      }
      sendAnalyticsEvent(currentPayload, addCommonData, isEventWithCatalogProducts)
    },
    [payloadRef, sendAnalyticsEvent, addCommonData, isEventWithCatalogProducts]
  )
}

/** Provide Formatted Cart Items in Data Layer Effect
 * @example
 * useAnalyticsEvent({
 *   id: 'VirtualPage-View'
 * })
 */
export const useAnalyticsEvent = (
  payload: AnalyticsEventPayload,
  deps: unknown[] = [],
  addCommonData = false
) => {
  const sendAnalyticsEvent = useSendAnalyticsEvent(payload, addCommonData)

  useEffect(() => {
    sendAnalyticsEvent()
    // we can guarantee that all the dependency are satisfied as deps are additional dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendAnalyticsEvent, ...deps])
}

/** Send Analytics error from callback */
export const useSendAnalyticsError = (error: ErrorData) => {
  const errorRef = useRefValue(error)

  return useCallback(() => {
    sentAnalitycsError(errorRef.current)
  }, [errorRef])
}

/** Send Analytics error as Effect */
export const useAnalyticsError = (error: ErrorData) => {
  const sendError = useSendAnalyticsError(error)
  useEffect(() => {
    sendError()
  }, [sendError])
}

/** Provide Formatted Products in sendEvent Callback Payload
 * @example
 * const sendRemoveItemsFromCart = useSendAnalyticsProductsEvent({
 *  id: 'Prods-Delete',
 * })
 * sendRemoveItemsFromCart(product)
 */
export const useSendAnalyticsProductsEvent = (
  payload: AnalyticsEventPayload,
  addCommonData = false
) => {
  const mySelection = useSelector(state => state.frameAdvisor.mySelection)
  return useSendAnalyticsEvent((products: CustomProduct | CustomProduct[]) => {
    const productList = Array.isArray(products) ? products : [products]
    return {
      Products: formatProducts(productList, mySelection),
      ...payload,
    }
  }, addCommonData)
}

/** Hook to send analytics addToCart event */
export const useSendAnalyticsAddToCartEvent = (
  payload: AnalyticsEventPayload,
  addCommonData = false
) => {
  const mySelection = useSelector(state => state.frameAdvisor.mySelection)
  return useSendAnalyticsEvent((products: CustomProduct | CustomProduct[]) => {
    const productList = Array.isArray(products) ? products : [products]
    const prods = formatProducts(productList, mySelection)

    Object.keys(prods).forEach((key: string) => {
      delete prods[key]['Vm_IsUpcSupported']
      delete prods[key]['Conf_IsUpcSupported']
    })
    return {
      Products: prods,
      id: 'AddToCart',
      ...payload,
    }
  }, addCommonData)
}

/** Provide Formatted Cart Items in Data Layer Effect
 * @example
 * useAnalyticsCartEvent({
 *   id: 'VirtualPage-View'
 * }, items)
 */
export const useAnalyticsCartEvent = (
  payload: AnalyticsEventPayload,
  items: CartItemType[],
  deps: unknown[] = [],
  addCommonData = false
) => {
  const mySelection = useSelector(s => s.frameAdvisor.mySelection)
  const engravingDetailsItems = useSelector(s => s.engraving.items)

  const sendAnalyticsEvent = useSendAnalyticsEvent(
    {
      Products: formatCartItems(items, mySelection, engravingDetailsItems),
      ...payload,
    },
    addCommonData
  )

  useEffect(() => {
    sendAnalyticsEvent()
    // we can guarantee that all the dependency are satisfied as deps are additional dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendAnalyticsEvent, ...deps])
}

export const useAnalyticsNewSession = (store: Maybe<Store>) => {
  const dispatch = useDispatch()
  const hasSentTealiumNewSession = useSelector(s => s.session.hasSentTealiumNewSession)
  const sendTealiumNewSession = useSendAnalyticsEvent(
    {
      id: 'VirtualPage-View',
      Session_Status: 'Off',
      Events_SessionStart: '1',
    },
    true
  )

  useEffect(() => {
    if (store) {
      executeOnce(() => {
        if (!hasSentTealiumNewSession) {
          sendTealiumNewSession()
          dispatch(saveAnalyticsTealiumSended())
        }
      }, 'start-session-tealium')()
    }
    // we need to wait for store as events before store are dropped
  }, [store, sendTealiumNewSession, hasSentTealiumNewSession, dispatch])
}
