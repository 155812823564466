import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'

import config from 'config'
import possibleTypes from './fragmentTypes.json'

const cache = new InMemoryCache({
  possibleTypes: possibleTypes,
})

export const getClient = () => {
  const client = new ApolloClient({
    // By default, this client will send queries to the
    //  `/graphql` endpoint on the same host
    link: new HttpLink({ uri: config.middleware.URL }),
    connectToDevTools: true,
    cache,
  })
  return client
}
