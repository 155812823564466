import styled from 'styled-components'
import { Heading2, Paragraph } from '@abstract/sss-elements'
import { RBButton } from 'components/Button'

export const PerfectFrameModalWrapper = styled.div<{ color: string }>`
  position: fixed;
  right: 1.818rem;
  bottom: 13.863rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 20.54rem;
  border-radius: 0.5rem;
  background-image: ${({ color }) => `linear-gradient(to left, ${color})`};
`

export const Title = styled(Heading2)`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fonts.sizes.largest};
  color: ${({ theme }) => theme.colors.white};
`

export const Text = styled(Paragraph)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 0.909rem;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`

export const Cta = styled(RBButton)`
  color: ${({ theme }) => theme.colors.emperor};
  background-color: ${({ theme }) => theme.colors.white};
`

export const CloseBtn = styled(RBButton)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.25rem;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
`
