import { DefaultTheme, DefaultColors } from 'styled-components'

import { theme as engraving } from '@abstract/react-engraving'
import {
  barCodeScanModalsTheme as barCodeScanmodals,
  theme as productCard,
} from '@abstract/ss-product-elements'
import { darkTheme as touchkeyboard } from '@abstract/touch-keyboard'
import { theme as checkout } from '@abstract/react-checkout'
import { theme as elements } from '@abstract/ss-elements-ui'
import { theme as elementsNewUi } from '@abstract/sss-elements'

// To name colors and avoid similars use this tool
// http://chir.ag/projects/name-that-color/
// Convert new name in camelCase

export const colors: DefaultColors = {
  concrete: '#f2f2f2',
  mineShaft: '#333',
  emperor: '#555',
  alto: '#D8D8D8',
  altoLight: '#DADADA',
  whiteSmoke: '#f6f6f6',
  gray: '#888888',
  silver: '#cccccc',
  white: 'white',
  boulder: '#777',
  monza: '#d0021b',
  red: '#DC5454',
  green: '#79AF2C',
  darkBlue: '#192C50',
  lightBlue: '#88ABBA',
  CTARed: '#e80c00;',
  black: '#111',
  wildSand: '#f6f6f6',
  mercury: '#e2e2e2',
  doveGray: '#666',
}

const theme: DefaultTheme = {
  checkout: {
    ...checkout.checkout,
    // eslint-disable-next-line @typescript-eslint/camelcase
    checkbox_checked_bg_color: colors.mineShaft, // @grey

    // eslint-disable-next-line @typescript-eslint/camelcase
    checkbox_checked_border_color: colors.mineShaft, // @grey

    // eslint-disable-next-line @typescript-eslint/camelcase
    accordion_border_color: colors.mineShaft,

    // eslint-disable-next-line @typescript-eslint/camelcase
    input_border_color: colors.gray,
  },
  elements,
  fonts: {
    primary: 'Oswald,Helvetica,Arial,sans-serif',
    primaryLight: 'Lato,Helvetica,Arial,sans-serif',
    primaryMedium: 'Oswald-Medium,Helvetica,Arial,sans-serif',
    secondary: 'Lato,Helvetica,Arial,sans-serif',
    ctaText: 'Oswald,Helvetica,Arial,sans-serif',
    oakleyPrimary: 'ScoutCond,Helvetica,Arial,sans-serif',
    oakleySecondary: '"MorePro-CondBook",Helvetica,Arial,sans-serif',
    stellaPrimaryFont: '"Oswald","Gilmer",Roboto,Helvetica,Arial,sans-serif',
    stellaSecondaryFont: '"Lato","Gilmer",Roboto,Helvetica,Arial,sans-serif',
    sizes: {
      tiny: '0.6364rem', //14px
      smallest: '0.727rem', //16px
      small: '0.818rem', //18px
      medium: '1rem', //22px
      large: '1.091rem', //24px
      largest: '1.455rem', //32px
      huge: '1.8182rem', //40px
    },
  },
  radioColors: {
    gold: '#d8af58',
    silver: '#c0c0c0',
    rose: '#ffe4e1',
  },
  colors,
  inputFontColor: '#333',
  fontInput: 'Roboto,Helvetica,Arial,sans-serif',
  fontHeaderTitle: 'Roboto,Helvetica,Arial,sans-serif',
  fontActionButton: 'Roboto,Helvetica,Arial,sans-serif',
  engraving,
  productCard: {
    ...productCard,
    color: colors.emperor,
    imageMarginBottom: '0',
    wishlistButtonTop: '0',
    imageMaxWidth: '100%',
    rowGutter: '0rem',
    priceOrder: '2',
    brandNameOrder: '1',
    modelCodeOrder: '5',
    modelNameOrder: '3',
    colorsOrder: '4',
    prescriptionOrder: '6',
  },
  touchkeyboard,
  barCodeScanmodals,
  elementsNewUi: {
    ...elementsNewUi,
    roundbutton: {
      outLinedDisabledColor: '#888888',
      outLinedColor: colors.black,
      outLinedBorderColor: colors.black,
      outLinedBorderSize: '2px',
      containedColor: colors.white,
      containedBorderColor: 'transparent',
      containedBackGround: colors.CTARed,
      containedDisabledBackGround: '#888888',
      smallHeight: '1.59rem',
      smallFontSize: '0.73rem',
      mediumHeight: '2.27rem',
      mediumFontSize: '0.91rem',
      mediumLargeHeight: '3.1818rem',
      mediumLargeFontSize: '1.36rem',
      largeHeight: '3.64rem',
      largeFontSize: '1.36rem',
    },
  },
}

export { theme }
