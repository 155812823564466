import { PrivacyPolicyModal } from 'components/PrivacyPolicyModal'
import React, { useState } from 'react'
import { Trans, translate, WithNamespaces } from 'react-i18next'
import { DisclaimerWrapper } from './DisclaimerWrapper'

interface DisclaimerProps extends WithNamespaces {
  className?: string
}

const DisclaimerWithoutI18N: React.FC<DisclaimerProps> = ({ className }) => {
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false)

  return (
    <DisclaimerWrapper className={className}>
      <Trans i18nKey="Core.disclaimer">
        Product images and virtual model may distort or otherwise not provide an accurate
        representation of the product and its color(s), textures, size as well as your face and
        facial features and the fit of the glasses. Read our
        <a data-element-id="Footer_Privacy" onClick={() => setPrivacyModalVisible(true)}>
          Privacy policy.
        </a>
      </Trans>

      <PrivacyPolicyModal
        visible={privacyModalVisible}
        onCancel={() => setPrivacyModalVisible(false)}
      />
    </DisclaimerWrapper>
  )
}

export const Disclaimer = translate('translation')(DisclaimerWithoutI18N)
