import { Drawer as SSSDrawer, DrawerPortal as SSSDrawerPortal } from '@abstract/sss-elements'
import React from 'react'

export const DRAWER_CONTAINER_CLASS_NAME = 'ss-drawer__container'

export const Drawer: React.FC<React.ComponentProps<typeof SSSDrawer>> = props => {
  return <SSSDrawer {...props} containerClassName={DRAWER_CONTAINER_CLASS_NAME} />
}

export const DrawerPortal: React.FC = ({ children }) => {
  return (
    <SSSDrawerPortal containerClassName={DRAWER_CONTAINER_CLASS_NAME}>{children}</SSSDrawerPortal>
  )
}
