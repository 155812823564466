import { useState } from 'react'
import { useSelector } from 'react-redux'
import { CustomProduct } from 'types/product'

export const useCustomerOrder = () => {
  const [isCustomerOrderModalVisible, toggleCustomerOrderModalVisible] = useState(false)
  const [product, setProduct] = useState<CustomProduct>()

  const isCustomerOrder = useSelector(s => !!s.customerOrder.salesOrderId)

  const handleToggleVisible = () => {
    toggleCustomerOrderModalVisible(v => !v)
  }

  const isCompletePairOrderType = useSelector(s => s.customerOrder.orderType) === 'COMPLETE_PAIR'

  return {
    isCustomerOrderModalVisible,
    setProduct,
    handleToggleVisible,
    product,
    isCustomerOrder,
    isCompletePairOrderType,
  }
}

export const useRedirectToCustomerOrderApp = () => {
  const isCustomerOrder = useSelector(s => !!s.customerOrder.salesOrderId)
  const redirectToCustomerOrderApp = () => {
    if (isCustomerOrder) {
      window.location.replace('ciao-wrapper://')
    }
  }
  return redirectToCustomerOrderApp
}
