import { ProductInfo, WishlistButton } from '@abstract/ss-product-elements'
import CardProduct, { LoveHateButtonPosition } from 'components/CardProduct'
import { FrameAdvisorProductCard, LoveHateBtnWrapper } from '@abstract/sss-frame-advisor'
import styled from 'styled-components'

export const ItemContainer = styled.div`
  margin: -1rem;
  overflow-y: auto;
  flex-grow: 1;
  width: calc(100% + 2rem);
`

export const ProductList = styled.div`
  padding-top: 2.818rem;
  display: flex;
  flex-wrap: wrap;
`
export const CTAContainer = styled.div`
  margin-bottom: 1.1364rem;
  text-align: center;

  .back-to-top {
    margin-right: 0.636rem;
    border: 1px solid #777777;
    color: #555555;
    background: none;
  }

  button {
    font-size: 0.909rem;
    min-width: 9.091rem;
  }
`

export const FrameAdvisorCardProduct = styled(CardProduct)`
  ${WishlistButton} {
    display: none;
  }

  ${ProductInfo} {
    > * {
      max-width: 100%;
    }
  }
`

export const ScrollableContainer = styled.div``

export const StyledFrameAdvisorProductCard = styled(FrameAdvisorProductCard)`
  ${LoveHateBtnWrapper} {
    z-index: 1;
    ${LoveHateButtonPosition}
  }
`
