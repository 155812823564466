import React from 'react'
import blackLogo from '../../assets/logo-black.svg'
import whiteLogo from '../../assets/logo-white.svg'
import { LogoColor, LogoColors } from '../../types/common'

const logoColors: LogoColors = {
  black: blackLogo,
  white: whiteLogo,
}

export const Logo: React.FC<{ logoColor?: LogoColor; className?: string }> = ({
  logoColor = 'black',
  className,
}) => <img src={logoColors[logoColor]} alt="RB Logo" className={className} />
