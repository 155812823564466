import React from 'react'
import styled, { keyframes } from 'styled-components'

// from - twitter bootstrap with love.
// https://getbootstrap.com/docs/4.4/components/spinners/#growing-spinner
const spinnerGrow = keyframes`
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
`

export const Spin = styled.div`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  opacity: 0;
  vertical-align: text-bottom;
  background-color: black;
  animation: ${spinnerGrow} 1.5s linear infinite;
`

export const Loading: React.FC<{ className?: string }> = ({ className }) => {
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 320px)',
  }

  return (
    <div style={style} className={className}>
      <Spin />
    </div>
  )
}

export default Loading
