
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ContentV2Link"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ContentV2Link"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"customizable"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"engravable"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"polarizedLens"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"vto"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"gender"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"productType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"pageType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"brand"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"slug"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"url"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"analyticsData"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dataElementId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"dataDescription"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":214}};
    doc.loc.source = {"body":"fragment ContentV2Link on ContentV2Link {\n  id\n  customizable\n  engravable\n  polarizedLens\n  vto\n  gender\n  productType\n  pageType\n  brand\n  slug\n  url\n  analyticsData {\n    dataElementId\n    dataDescription\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
