import React from 'react'
import { translate, WithNamespaces } from 'react-i18next'
import { Button } from 'antd'

import {
  ErrorWidget,
  ErrorWidgetTitle,
  ErrorWidgetLabels,
  ErrorWidgetDetails,
  ErrorWidgetMessages,
  ErrorWidgetList,
  ErrorWidgetMessage,
} from './styles'
import { ServerParseError, ServerError } from '@apollo/client'
import { GraphQLError } from 'graphql'
import { useAnalyticsError } from 'containers/AnalyticsProvider'

type ErrorTypes = Error | ServerParseError | ServerError | GraphQLError

interface WithWrapperProps {
  withWrapper?: boolean
  type?: string
}

interface ErrorwidgetProps extends WithNamespaces {
  className?: string
  errors: ErrorTypes[]
  actionLabel?: string
  type: string
  action?: () => void
  withWrapper?: boolean
}

const SubErrorswidget: React.FC<{ errors: ErrorTypes[] }> = ({ errors }) => (
  <ErrorWidgetList>
    {errors.map((e, i) => (
      <ErrorWidgetMessage key={`error-${i}`}>{e.message}</ErrorWidgetMessage>
    ))}
  </ErrorWidgetList>
)

const ErrorWidgetWithoutI18N: React.FC<ErrorwidgetProps> = props => {
  const { className, t, errors, type, action, actionLabel } = props

  useAnalyticsError({ errors, type })

  return (
    <ErrorWidget className={className}>
      <ErrorWidgetLabels>
        <ErrorWidgetTitle>{`${type} - something went wrong!`}</ErrorWidgetTitle>{' '}
        {/* see: https://react.i18next.com/components/interpolate */}
        <Button
          onClick={() => {
            action ? action() : window.location.reload()
          }}
        >
          {actionLabel || t('Core.reload')}
        </Button>
      </ErrorWidgetLabels>
      <ErrorWidgetDetails>
        <ErrorWidgetMessages>
          <ErrorWidgetList>
            {errors.map((e, i) => (
              <ErrorWidgetMessage key={`error-${i}`}>
                {e.message}
                {'result' in e && <SubErrorswidget errors={e.result.errors} />}
              </ErrorWidgetMessage>
            ))}
          </ErrorWidgetList>
        </ErrorWidgetMessages>
      </ErrorWidgetDetails>
    </ErrorWidget>
  )
}

const withWrapper = <T extends unknown>(WrappedComponent: React.ComponentType<T>) => {
  const ErrorWidgetWrapper = (props: T & WithWrapperProps) => {
    const wrapperStyle = {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      padding: '1em 0',
      width: '100%',
    }

    if (props.withWrapper) {
      return (
        <div style={wrapperStyle}>
          <WrappedComponent {...props} />
        </div>
      )
    }
    return <WrappedComponent {...props} />
  }

  return ErrorWidgetWrapper
}

const ErrorwidgetWithoutHOC = translate('translations')(ErrorWidgetWithoutI18N)

export default withWrapper(ErrorwidgetWithoutHOC)
