declare global {
  interface Window {
    // eslint-disable-next-line no-undef
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

declare module 'react-redux' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultRootState extends AppState {}
}

import {
  createStore,
  applyMiddleware,
  compose,
  StoreEnhancer,
  Middleware,
  StoreEnhancerStoreCreator,
} from 'redux'
// import { routerMiddleware } from 'react-router-redux'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history/'
import storeReducer from './reducers/store'
import createRootReducer from './reducers'
import { StoreActionTypes } from './actions/store'
import { persistReducers, loadState } from './localStorage'
export const history = createBrowserHistory()

// Get the current location.
// const location = history.location

// Listen for changes to the current location.
history.listen(() => {
  // location is an object like window.location
  // console.log(action, location.pathname, location.state)
  const $body = document.querySelector('body')
  if ($body) {
    $body.scrollTop = 0
  }
})

const appReducer = createRootReducer(history)
const initialState = loadState()

const rootReducer = (state: AppReducerState, action: StoreActionTypes) => {
  state = storeReducer(state, action)
  return appReducer(state, action)
}

const enhancers: StoreEnhancer[] = []
const middleware: Middleware[] = [thunk]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose<StoreEnhancerStoreCreator>(
  applyMiddleware(...middleware),
  ...enhancers
)

const store = createStore(rootReducer, initialState, composedEnhancers)

persistReducers(
  store,
  'cart',
  'wishlist',
  'session',
  'engraving',
  'frameAdvisor',
  'customerOrder',
  'order'
)

export default store

export type AppStore = typeof store
export type AppState = ReturnType<typeof appReducer>
export type AppReducerState = Parameters<typeof appReducer>[0]
