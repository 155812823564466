import React, { useCallback } from 'react'
import { Product } from '../../types/product'
import {
  CardBodyStyled,
  CardProductLinkStyled,
  ColorsStyled,
  IconsStyled,
  NameStyled,
  PreviewStyled,
  PRICE_STYLE_RANGE,
  ProductCard,
  ProductInfo,
  ProductPriceStyled,
  StyledImg,
  WishlistButton,
  CardProductProps as CardProductPropsLib,
} from '@abstract/ss-product-elements'
import { DataAttrs } from '@abstract/ss-product-elements/dist/types/types/product'
import config from 'config'
import styled, { css } from 'styled-components'
import { useWishList } from 'hooks/useWishlist'
import { LikeIcon, VTOIcon } from '@abstract/sss-elements'
import CustomizeIcon from 'components/CustomizeIcon'
import { translate, WithNamespaces } from 'react-i18next'
import vtoImg from '@abstract/sss-elements/assets/vto.png'
import { dataDescriptionForProduct } from '@abstract/ss-adobe-analytics'
import PolarizedIconSrc from 'assets/icons/polarized.svg'

const ICON_SIZE = '2rem'
export const IMAGE_RATIO = '100%'

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  img {
    object-fit: scale-down;
    width: calc(${ICON_SIZE} * 0.6);
    height: calc(${ICON_SIZE} * 0.6);
  }
`

export const LoveHateButtonPosition = css`
  position: absolute;
  top: -3.3rem;
  right: 0.6rem;
  margin: ${IMAGE_RATIO} 0 0;
  padding: 0;
  justify-content: flex-end;
`

export const LikeIconContainer = styled.div<{ notWishlisted?: boolean }>`
  margin: 0.6rem;
  width: 1.9rem;
  height: 1.9rem;
`

export const CustomizeIconContainer = styled.div`
  position: absolute;
  right: 1rem;
  bottom: -1.5rem;
  width: 1.36rem;
  height: 1.36rem;
`

export const PolarizedIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 1.5rem;
`

const StyledWishlistedIcon = () => (
  <LikeIconContainer>
    <LikeIcon wishlisted />
  </LikeIconContainer>
)

const StyledNotWishlistedIcon = () => (
  <LikeIconContainer notWishlisted>
    <LikeIcon />
  </LikeIconContainer>
)

const StyledCustomizeIcon = () => (
  <CustomizeIconContainer>
    <CustomizeIcon />
  </CustomizeIconContainer>
)

const StyledVtoIcon = () => (
  <IconContainer>
    <VTOIcon src={vtoImg} />
  </IconContainer>
)

export const ProductCardStyled = styled((props: CardProductPropsLib<Product>) => (
  <ProductCard {...props} />
))<{ displayWishlistIconAtBottom?: boolean }>`
  padding: 0;
  width: 100%;
  height: auto;

  ${CardProductLinkStyled} {
    z-index: 2;
  }

  ${WishlistButton} {
    z-index: 2;
    position: absolute;
    right: 0.6rem;
    width: 2.7275rem;
    height: 2.7275rem;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    ${({ displayWishlistIconAtBottom }) =>
      displayWishlistIconAtBottom
        ? css`
            margin-top: -4rem;
          `
        : css`
            top: 0;
          `}

    & > * {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }

  ${ProductInfo} {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    width: 100%;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.mineShaft};
    text-align: left;

    > * {
      min-width: 35%; /* force two column */
      max-width: 60%; /* avoid one column with 5% gap */
    }
  }

  ${NameStyled} {
    overflow: hidden;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 0.7rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.black};
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 35%;
    max-width: 100%;
  }

  ${ProductPriceStyled} {
    font-family: ${({ theme }) => theme.fonts.secondary};
    line-height: 1em;
    text-align: left;
  }

  ${ColorsStyled} {
    position: absolute;
    top: -1.5rem;
    padding-top: 100%;
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: 0.7rem;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.emperor};
    text-align: left;
    text-transform: uppercase;

    ${ColorsStyled} {
      position: relative;
      top: 0;
      padding-top: 0;
    }
  }

  ${IconsStyled} {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0.78rem 0 0.78rem 0.78rem;
    padding: 0 0 calc(${IMAGE_RATIO} - ${ICON_SIZE});
    display: grid;
    grid-template-columns: repeat(auto-fill, ${ICON_SIZE});
    width: 100%;
    height: auto;

    &::after {
      content: '';
      display: block;
      width: 0;
      height: ${ICON_SIZE};
    }
  }

  ${PreviewStyled} {
    position: relative;
    padding: ${IMAGE_RATIO} 0 0;
    width: 100%;
    height: auto;
    background-color: ${({ theme }) => theme.colors.whiteSmoke};
  }

  ${StyledImg} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  ${CardBodyStyled} {
    padding: 0.7rem;
    align-items: stretch;
    height: 100%;
  }
`

interface CardProductProps {
  product: Product
  hidePrice?: boolean
  engravingEnabled?: boolean
  customizableEnabled?: boolean
  vtoEnabled?: boolean
  showRange?: boolean
  angle?: string
  gender?: string
  hilightModelCode?: boolean
  showIcons?: boolean
  showPrice?: boolean
  showProductBrandName?: boolean
  showProductCode?: boolean
  showProductName?: boolean
  className?: string
  sendAddToWishlistEvent?: Function
  dataAttrs?: DataAttrs
  previewQuality?: number
  additionalQueryStringAttributes?: { [key: string]: string | boolean | number | undefined }
  dressedOnImageUrl?: string
  displayWishlistIconAtBottom?: boolean
  showWishlistIcon?: boolean
}

const CardProduct: React.FC<CardProductProps & WithNamespaces> = ({
  product,
  engravingEnabled = true,
  customizableEnabled = true,
  vtoEnabled = true,
  showRange,
  angle = '000A',
  gender,
  hilightModelCode = false,
  showIcons = true,
  showPrice = true,
  showProductBrandName = false,
  showProductCode = false,
  showProductName = true,
  className,
  sendAddToWishlistEvent,
  dataAttrs,
  previewQuality,
  additionalQueryStringAttributes,
  dressedOnImageUrl,
  displayWishlistIconAtBottom = true,
  showWishlistIcon = true,
}) => {
  const { wishlisted, toggleItemToWishlist } = useWishList(product.UPC)

  const handleToggleWishlist = (product: Product) => {
    toggleItemToWishlist(product)
    if (!wishlisted) {
      sendAddToWishlistEvent && sendAddToWishlistEvent(product)
    }
  }

  const StyledPolarizedIcon = useCallback(
    () => (
      <PolarizedIconContainer>
        <img alt="polarized" src={PolarizedIconSrc} />
      </PolarizedIconContainer>
    ),
    []
  )

  const analyticsDataDescription = dataDescriptionForProduct(product)

  return (
    <ProductCardStyled
      className={className}
      angle={angle}
      baseURL={config.productImageUrl}
      customizableIcon={customizableEnabled ? StyledCustomizeIcon : undefined}
      engravingEnabled={engravingEnabled}
      gender={gender}
      hilightModelCode={hilightModelCode}
      //imageOptions={{ impolicy: 'whitebg' }}
      notWishlistedIcon={StyledNotWishlistedIcon}
      polarizedIcon={StyledPolarizedIcon}
      priceStyle={showRange ? PRICE_STYLE_RANGE : undefined}
      product={product}
      showIcons={showIcons}
      showPrice={showPrice}
      showProductBrandName={showProductBrandName}
      showProductCode={showProductCode}
      showProductName={showProductName}
      toggleItemToWishlist={handleToggleWishlist}
      vtoEnabled={vtoEnabled}
      vtoIcon={StyledVtoIcon}
      wishlisted={wishlisted}
      wishlistedIcon={StyledWishlistedIcon}
      dataAttrs={{ description: analyticsDataDescription, ...dataAttrs }}
      previewQuality={previewQuality}
      additionalQueryStringAttributes={additionalQueryStringAttributes}
      isAssortmentProductImage={config.isAssortmentProductImage}
      dressedOnImageUrl={dressedOnImageUrl}
      displayWishlistIconAtBottom={displayWishlistIconAtBottom}
      showWishlistIcon={showWishlistIcon}
    />
  )
}

export default translate('translation')(CardProduct)
