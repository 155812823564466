import { hideAllDrawer as hideAllDrawerAction } from 'actions/ui'
import { BarcodeIcon, CloseIcon } from '@abstract/sss-elements'
import SearchIcon from 'components/MenuIcons/SearchIcon'
import { useBarcodeScanner, isBarcodeScannerAvailable } from '@abstract/ss-elements-ui'
import Header from 'components/Header'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { SearchProducts } from './SearchProducts'
import { FloatingButton } from 'components/FloatingActions/styles'
import { useHistory } from 'react-router-dom'
import {
  getSearchValueFromQs,
  useDebounce,
  useAction,
  tablet,
  landscape,
  Device,
} from '@abstract/core'
import { Disclaimer } from 'components/Disclaimer'
import { translate, WithNamespaces } from 'react-i18next'
import config from 'config'
import { useStoreData } from 'data'
import { parseGrapqhQLError } from 'libs/errors'
import ErrorWidget from 'components/ErrorWidget'
import Loading from 'components/Loading'
import { RBButton } from 'components/Button'
import { DiscountBanner as UnstyledDiscountBanner } from 'components/DiscountBanner'
import { Drawer } from 'components/core/Drawer'

const SEARCH_DEBOUNCE_INTERVAL = 1000

const SearchWrapper = styled.div`
  position: absolute;
  top: 12.818rem;
  padding: 0 4.909rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 76vh;
  ${tablet(`
    top: 12rem;
  `)}
  ${landscape(
    Device.tablet,
    `
    height: 70vh;
  `
  )}
`

const StyledDiscountBanner = styled(UnstyledDiscountBanner)`
  position: fixed;
  top: 8rem;
`

const SearchFloatingButtonInner = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const SearchFloatingButton = styled(FloatingButton)`
  z-index: 1;
  position: absolute;
  top: 18.75vh;
  right: 0;
  padding: 1.364rem;
  border: 1px solid ${({ theme }) => theme.colors.concrete};
  ${tablet(`
    top: 12rem;
  `)}
`

const SearchInputWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
`

const SearchInput = styled.input`
  margin: 0;
  padding: 0.2em;
  width: 60%;
  border: 0;
  outline: 0;
  font-size: 1.36rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.concrete};
`

const SearchIconWrapper = styled.div`
  width: 2.73rem;
  height: 2.73rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.concrete};

  > img {
    padding: 0;
  }
`

const BarcodeScannerButton = styled(RBButton)`
  margin: 2.541rem auto 0;
  min-width: 14.773rem;

  svg {
    margin-top: 0.091rem;
    min-width: 40px;
    width: 40px;
    height: 20px;
  }
`

const BarcodeScannerButtonContainer = styled.div`
  margin: 0 auto;
  flex-shrink: 0;
`

const BarcodeButtonContent = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  white-space: nowrap;
`

export const SearchDrawerWithoutLanguage: React.FC<WithNamespaces> = ({ t }) => {
  const history = useHistory()
  const initialQuery = getSearchValueFromQs(history.location)
  const [query, setQuery] = useState(initialQuery)
  const hideAllDrawer = useAction(hideAllDrawerAction)
  const debouncedQuery = useDebounce(query, SEARCH_DEBOUNCE_INTERVAL)
  const { data: storeData, loading: storeLoading, error: storeError } = useStoreData()

  const openBarcodeScanner = useBarcodeScanner({
    t,
    timeout: config.resetAppTimeOut,
    assetsLogoSrc: `${config.assets.URL}/logos/rayban/logo-for-barcode-page.png`,
  })

  const visible = useSelector(({ ui }) => {
    return ui.searchDrawerIsVisible
  })

  if (storeError) {
    return <ErrorWidget {...parseGrapqhQLError(storeError)} withWrapper />
  }

  if (!storeData || storeLoading) {
    return <Loading />
  }

  const isBarcodeScannerEnabled = storeData && storeData.store.barcodeReaderEnabled

  return (
    <Drawer
      position="left"
      variant="fullscreen"
      visible={visible}
      unmountOnExit={false}
      onOverlayClick={hideAllDrawer}
    >
      <Header />
      <StyledDiscountBanner />
      <SearchFloatingButton type="button" onClick={hideAllDrawer}>
        <SearchFloatingButtonInner>
          <CloseIcon />
        </SearchFloatingButtonInner>
      </SearchFloatingButton>
      <SearchWrapper>
        <SearchInputWrapper>
          <SearchInput
            type="search"
            value={query}
            onChange={e => setQuery(e.target.value)}
            autoFocus={visible}
          />
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
        </SearchInputWrapper>
        {isBarcodeScannerAvailable() && isBarcodeScannerEnabled && (
          <BarcodeScannerButtonContainer>
            <BarcodeScannerButton
              data-element-id="MainNav_SearchBarcode"
              onClick={() => {
                hideAllDrawer()
                openBarcodeScanner()
              }}
            >
              <BarcodeButtonContent>
                <BarcodeIcon fill="#fff" height="50%" />
                <div> {t('search.barcode')}</div>
              </BarcodeButtonContent>
            </BarcodeScannerButton>
          </BarcodeScannerButtonContainer>
        )}

        {debouncedQuery && <SearchProducts query={debouncedQuery} store={storeData?.store} />}
      </SearchWrapper>
      <Disclaimer />
    </Drawer>
  )
}

export const SearchDrawer = translate('translation')(SearchDrawerWithoutLanguage)
