import { ApolloError } from '@apollo/client'

export const parseGrapqhQLError = (error: ApolloError) => {
  const { networkError, graphQLErrors } = error
  if (networkError) {
    return {
      type: 'Network error',
      errors: [networkError],
    }
  }

  const type = graphQLErrors[0].extensions?.type || 'Generic Error'

  return {
    type,
    errors: [...graphQLErrors],
  }
}
