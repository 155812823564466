import { updateVisited } from 'actions/frameAdvisor'
import { CloseIcon } from '@abstract/sss-elements'
import { useSendAnalyticsEvent } from 'containers/AnalyticsProvider'
import { useStoreIndentity } from 'hooks/useStoreIdentity'
import React, { useCallback, useEffect } from 'react'
import { translate, WithNamespaces } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CloseBtn, Cta, PerfectFrameModalWrapper, Text, Title } from './styles'
import { useOppositeDebounce } from '@abstract/core'

const SHOW_DEBOUNCE_INTERVAL = 5000

interface PerfectFrameModalProps extends WithNamespaces {
  colors: string[]
}

const PerfectFrameModalWithoutI18N: React.FC<PerfectFrameModalProps> = ({ colors, t }) => {
  const isShowPopup = useOppositeDebounce(false, SHOW_DEBOUNCE_INTERVAL)
  const color = colors.join(',')
  const { basePath } = useStoreIndentity()
  const { isFrameAdvisorVisited } = useSelector(s => s.frameAdvisor)
  const dispatch = useDispatch()
  const handleCloseModal = useCallback(() => dispatch(updateVisited(true)), [dispatch])

  /* eslint-disable @typescript-eslint/camelcase */
  const sendOverlayViewEvent = useSendAnalyticsEvent({
    id: 'Overlay-View',
    Page_Section2: 'PushNotification',
  })

  const shouldShowPopup = !isFrameAdvisorVisited && isShowPopup

  useEffect(() => {
    if (shouldShowPopup) sendOverlayViewEvent()
  }, [sendOverlayViewEvent, shouldShowPopup])

  if (!shouldShowPopup) return null // TODO add flag from store `frameAdvisorEnabled` when it will be ready

  return (
    <PerfectFrameModalWrapper color={color}>
      <CloseBtn onClick={handleCloseModal} data-element-id="PushNot_FrameFind-Close">
        <CloseIcon />
      </CloseBtn>
      <Title>{t('FrameAdvisor.perfectFrame.title')}</Title>
      <Text>{t('FrameAdvisor.perfectFrame.text')}</Text>
      <Cta as={Link} to={`${basePath}/frame-advisor/fsa`} data-element-id="PushNot_FrameFind-Start">
        {t('FrameAdvisor.perfectFrame.cta')}
      </Cta>
    </PerfectFrameModalWrapper>
  )
}

export const PerfectFrameModal = translate('translation')(PerfectFrameModalWithoutI18N)
