import { CartState } from '@abstract/react-checkout/dist/types/types/cart'
import { stellaBrandId } from 'data'
import { CartItem } from 'types/cart'

/**
 * Check if cart has some custom product in it
 *
 * @param {object} cart - object of shape cart (libs/cart)
 * @return {boolean} true if cart has at least one custom product
 */
export const hasCustomItemInCart = (cart: CartState) => {
  return cart.items.some(p => p.product.hasOwnProperty('customItem'))
}

/**
 * Check if cart has some standard product in it
 *
 * @param {object} cart - object of shape cart (libs/cart)
 * @return {boolean} true if cart has at least one standard product
 */
export const hasStandardItemInCart = (cart: CartState) => {
  return cart.items.some(p => !p.product.hasOwnProperty('customItem'))
}

/**
 * Check if cart is mixed of normal products and custom products (they have customItem obj).
 *
 * @param {object} cart - object of shape cart (libs/cart)
 * @return {boolean} true if cart is with 2 or more items with mixed products
 */
export const isMixedCart = (cart: CartState) => {
  if (cart.items.length < 2) return false

  return hasCustomItemInCart(cart) && hasStandardItemInCart(cart)
}

/**
 * Check if cart is mixed of normal products and stella products.
 *
 * @param {object} items - object of items in cart
 * @return {boolean} true if cart is with 2 or more items with mixed products
 */
export const isMixedCartStella = (items: CartItem[]) => {
  if (items.length < 2) return false

  const stellaProducts = items.filter(item => item.product.originalBrandCode === stellaBrandId)

  return stellaProducts.length !== 0 && stellaProducts.length !== items.length
}

export const isCustomProduct = (item: CartItem) => item.product?.customItem

export const isStellaProductWithPrescription = (item: CartItem) =>
  item.product.originalBrandCode === stellaBrandId && !!item.product.roxable
