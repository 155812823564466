import { useFrameAdvisorFsaVideoProfile } from 'data'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

export const useFrameAdvisorVideoProfile = () => {
  const videoId = useSelector(state => state.frameAdvisor.fsaVideoId)
  const [fetchVideoProfile, { data, loading, error }] = useFrameAdvisorFsaVideoProfile(
    videoId || ''
  )

  useEffect(() => {
    if (videoId)
      fetchVideoProfile({
        variables: {
          videoId,
        },
      })
  }, [fetchVideoProfile, videoId])

  // If there is no videoId we must not return the cached value
  return { data: videoId ? data : null, loading, error }
}
