import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { translate, WithNamespaces } from 'react-i18next'
import { isIPadView } from '@abstract/core'
import { ProductTypeValues } from 'types/filter'
import { pushToDataTrack } from '@abstract/ss-adobe-analytics'

const SearchProductTypeTabsWrapper = styled.ul`
  max-width: 100%;
  margin: 2rem 0 0;
  padding: 0 1rem;
  display: flex;
  gap: 1.818rem;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
  min-height: 3rem;
`

const SearchProductTypeFilterTab = styled.li<{ isActive?: boolean }>`
  padding: 0.273rem 0.273rem 0;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 0.9091rem;
  font-weight: 700;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.black : theme.colors.alto)};
  line-height: 1;
  text-align: center;
  text-transform: capitalize;
  border-bottom: 2px solid
    ${({ theme, isActive }) => (isActive ? theme.colors.black : 'transparent')};
`

interface ProductTypeTabsProps extends WithNamespaces {
  filtersProductType: ProductTypeValues[]
  selectedProductType: ProductTypeValues
  onChange: (v: ProductTypeValues) => void
}

const ProductTypeTabsWithoutI18n: React.FC<ProductTypeTabsProps> = ({
  filtersProductType,
  selectedProductType,
  onChange,
  t,
}) => {
  const wrapperRef = useRef<HTMLUListElement | null>(null)
  const isIPad = isIPadView()

  useEffect(() => {
    if (isIPad) return
    const wrapper = wrapperRef.current
    if (wrapper) {
      const activeTab = wrapper.children[filtersProductType.indexOf(selectedProductType)]
      activeTab && activeTab.scrollIntoView({ inline: 'center' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersProductType, selectedProductType])

  const onProductTypeClick = (p: ProductTypeValues, i: number) => {
    onChange(p)
    pushToDataTrack({
      id: 'Click',
      // eslint-disable-next-line @typescript-eslint/camelcase
      data_element_id: `MainNav_Results_Tab${i}`,
      // eslint-disable-next-line @typescript-eslint/camelcase
      data_description: p,
    })
  }

  return (
    <SearchProductTypeTabsWrapper ref={wrapperRef}>
      {filtersProductType.map((p, index) => (
        <SearchProductTypeFilterTab
          isActive={p === selectedProductType}
          key={p}
          onClick={() => onProductTypeClick(p, index)}
        >
          {t(`PlpFiltersproductType.${p.toLowerCase()}`)}
        </SearchProductTypeFilterTab>
      ))}
    </SearchProductTypeTabsWrapper>
  )
}

export const ProductTypeTabs = translate()(ProductTypeTabsWithoutI18n)
