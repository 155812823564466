import React from 'react'
import styled from 'styled-components'
import { translate, WithNamespaces } from 'react-i18next'

export const SwitcherWithLableStyled = styled.div`
  margin: 0 1.455rem;
  padding: 1.045rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 1.2727rem;
  font-weight: 400;
  color: black;
`
export const LabelWrapper = styled.div``

export const Switcher = styled.div<{ checked: boolean }>`
  margin: ${({ checked }) => (checked ? '0  0 0 0.38rem' : '0 0.38rem 0 0')};
  width: 1.56rem;
  height: 1.56rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
`

export const Label = styled.label<{ checked: boolean }>`
  padding: 0.38rem;
  display: flex;
  flex-direction: ${({ checked }) => (checked ? 'row' : 'row-reverse')};
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  height: 2.1rem;
  border-radius: 1rem;
  cursor: pointer;
  font-size: 0.875rem;
  color: white;
  background-color: ${props => (props.checked ? 'black' : props.theme.colors.silver)};

  & input {
    display: none;
  }
`

export const SwitcherValue = styled.div`
  font-size: 0.72rem;
`

type SwitcherWithLabelProps = React.ComponentProps<typeof Switcher> & {
  className?: string
  disabled?: boolean
  checked: boolean
  label: string
}

export const SwitcherWithLabelWithoutI18n: React.FC<SwitcherWithLabelProps & WithNamespaces> = ({
  disabled,
  checked,
  label,
  t,
  ...props
}) => (
  <SwitcherWithLableStyled>
    {label}
    <Label checked={checked}>
      <input type="checkbox" {...props} disabled={disabled} checked={checked} />
      <SwitcherValue>{checked ? t('PlpFilters.on') : t('PlpFilters.off')}</SwitcherValue>
      <Switcher checked={checked} />
    </Label>
  </SwitcherWithLableStyled>
)

export const SwitcherWithLabel = translate('translation')(SwitcherWithLabelWithoutI18n)
