import {
  getKeyboardLayout,
  NavigationProvider,
  ResetProvider,
  useDisablePinchZoom,
  usePreventContextMenu,
} from '@abstract/core'
import { setReturnUrl } from '@abstract/one-configurator'
import {
  ConfigOverride,
  EnviromentInfoPrint,
  useConfigOverrideExpiration,
} from '@abstract/react-environment-info'
import { AutofillStoreIdentityParams } from '@abstract/sss-elements'
import { VKeyboardProvider } from '@abstract/touch-keyboard'
import 'antd/dist/antd.less'
import { AppRouteActions } from 'components/AppRouteActions'
import { BarcodeActionsWrapper } from 'components/AppRouteActions/BarcodeScanActions'
import { AppRouteDisclaimer } from 'components/AppRouteDisclaimer'
import ErrorWidget from 'components/ErrorWidget'
import { FrameAdvisorRouteActions } from 'components/FrameAdvisor'
import Loading from 'components/Loading'
import { SearchDrawer } from 'components/SearchDrawer'
import { VMMV_POLICY_COOKIE_KEY, VMMV_SAVED_STATE_KEY } from 'components/VirtualMirror/constants'
import config from 'config'
import { ConnectedRouter } from 'connected-react-router'
import { AnalyticsProvider, useAnalyticsNewSession } from 'containers/AnalyticsProvider'
import { useStoreData } from 'data'
import { Location } from 'history'
import { useRedirectToCustomerOrderApp } from 'hooks/useCustomOrder'
import { useResetSS } from 'hooks/useResetSS'
import { DEFAULT_LANG, DEFAULT_STORE, DEFAULT_STORETYPE } from 'hooks/useStoreIdentity'
import { parseGrapqhQLError } from 'libs/errors'
import React, { Fragment, Suspense, useCallback, useEffect } from 'react'
import { translate, WithNamespaces } from 'react-i18next'
import { Provider } from 'react-redux'
import { matchPath, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import { defaultConfig } from './config/config.base'
// CONTAINERS
import ServicePage from './containers/Service'
import i18n from './i18n'
import { useVKeyboard } from './libs/utils'
import store, { history } from './store'

const Home = React.lazy(() => import('./containers/Home'))
const ProductsList = React.lazy(() => import('./containers/ProductsList'))
const ProductDetails = React.lazy(() => import('./containers/ProductDetails'))
const Wishlist = React.lazy(() => import('./containers/Wishlist'))
const Brands = React.lazy(() => import('./containers/Brands'))
const Cart = React.lazy(() => import('./containers/Cart'))
const Checkout = React.lazy(() => import('./containers/Checkout'))
const ThankYou = React.lazy(() => import('./containers/ThankYou'))
const FrameAdvisorSurvey = React.lazy(() => import('./containers/FrameAdvisorSurvey'))
const FrameAdvisorFaceappConnect = React.lazy(() =>
  import('./containers/FrameAdvisorFaceappConnect')
)
const FrameAdvisorFaceappLogout = React.lazy(() => import('./containers/FrameAdvisorFaceappLogout'))
const FrameAdvisorProductType = React.lazy(() => import('./containers/FrameAdvisorProductType'))
const FrameAdvisorPlaylist = React.lazy(() => import('./containers/FrameAdvisorPlaylist'))
const FrameAdvisorSuggestionPage = React.lazy(() => import('./containers/FrameAdvisorSuggestion'))
const RbCustomDetails = React.lazy(() => import('./containers/RbCustomDetails'))
const PageNotFound = React.lazy(() => import('./containers/PageNotFound'))
const StellaHome = React.lazy(() => import('./containers/StellaHome'))

interface RoutesProps extends RouteComponentProps {
  rbCustomEnabled: boolean
}

const Routes: React.FC<RoutesProps> = ({ match, rbCustomEnabled }) => (
  <Fragment>
    <FrameAdvisorRouteActions />
    <AppRouteActions />
    <AppRouteDisclaimer />
    <Switch>
      <Route exact path={`${match.url}`} component={Home} />
      <Route exact path={`${match.url}/products`} component={ProductsList} />
      <Route exact path={`${match.url}/product/:model/:moco/:upc`} component={ProductDetails} />
      <Route exact path={`${match.url}/wishlist`} component={Wishlist} />
      <Route exact path={`${match.url}/brands`} component={Brands} />
      <Route exact path={`${match.url}/cart`} component={Cart} />
      <Route exact path={`${match.url}/cart/checkout`} component={Checkout} />
      <Route exact path={`${match.url}/cart/checkout/thank-you`} component={ThankYou} />
      <Route
        exact
        path={`${match.url}/custom/rb`}
        render={props =>
          rbCustomEnabled ? <RbCustomDetails {...props} /> : <Redirect to={match.url} />
        }
      />
      <Route
        exact
        path={`${match.url}/custom/rb/:modelCode/:recipeId?`}
        render={props =>
          rbCustomEnabled ? <RbCustomDetails {...props} /> : <Redirect to={match.url} />
        }
      />
      <Route
        exact
        path={`${match.url}/frame-advisor/survey/:stepId?`}
        component={FrameAdvisorSurvey}
      />
      <Route
        exact
        path={`${match.url}/frame-advisor/fsa/:stepId?`}
        render={props => <FrameAdvisorSurvey fsaConnector={true} {...props} />}
      />
      <Route
        exact
        path={`${match.url}/frame-advisor/connected`}
        component={FrameAdvisorProductType}
      />
      <Route
        exact
        path={`${match.url}/frame-advisor/connectfsa/:videoId?`}
        component={FrameAdvisorFaceappConnect}
      />
      <Route
        exact
        path={`${match.url}/frame-advisor/logoutfsa`}
        component={FrameAdvisorFaceappLogout}
      />
      <Route exact path={`${match.url}/frame-advisor/playlist`} component={FrameAdvisorPlaylist} />
      <Route
        exact
        path={`${match.url}/frame-advisor/playlist/:playlistId`}
        component={FrameAdvisorSuggestionPage}
      />
      <Route
        exact
        path={`${match.url}/:brand([a-z0-9]{2})`}
        render={props => (
          <Redirect to={`${match.url}/products?brand=${props.match.params.brand}`} />
        )}
      />
      <Route exact path={`${match.url}/scan-barcode`} component={BarcodeActionsWrapper} />
      <Route exact path={`${match.url}/stella-home/:defaultModel?`} component={StellaHome} />
      <Route component={PageNotFound} />
    </Switch>
  </Fragment>
)

const Base: React.FC<RouteComponentProps> = props => {
  const { data: storeData, error: storeError } = useStoreData()

  useAnalyticsNewSession(storeData?.store)

  if (storeError) {
    return <ErrorWidget {...parseGrapqhQLError(storeError)} withWrapper />
  }

  if (!storeData) {
    return <Loading />
  }

  const { rbCustomEnabled } = storeData.store

  return (
    <Suspense fallback={<Loading />}>
      <Routes {...props} rbCustomEnabled={rbCustomEnabled} />
    </Suspense>
  )
}

const resetVMMVState = () => {
  document.cookie = `${VMMV_POLICY_COOKIE_KEY}=false; path=/`
  localStorage.removeItem(VMMV_SAVED_STATE_KEY)
}

const BaseWrapper: React.FC<RouteComponentProps> = props => {
  const redirectToCustomerOrderApp = useRedirectToCustomerOrderApp()
  const resetSS = useResetSS()

  const onReset = useCallback(() => {
    resetSS()
    resetVMMVState()
    redirectToCustomerOrderApp()
  }, [redirectToCustomerOrderApp, resetSS])

  return (
    <ResetProvider onReset={onReset} timeoutMs={config.resetAppTimeOut} resetKeyPrefix="rb-ui">
      <Base {...props} />
      <SearchDrawer />
    </ResetProvider>
  )
}

const AutofillStoreIdentityParamsWrapper: React.FC<RouteComponentProps> = props => {
  const { data: storeData, error: storeError } = useStoreData()

  if (storeError) {
    return <ErrorWidget {...parseGrapqhQLError(storeError)} withWrapper />
  }

  return (
    <AutofillStoreIdentityParams
      {...props}
      i18n={i18n}
      storeData={storeData}
      defaultId={DEFAULT_STORE}
      defaultType={DEFAULT_STORETYPE}
      defaultLang={DEFAULT_LANG}
      loader={Loading}
    />
  )
}

type AppProps = WithNamespaces

const App = ({ t }: AppProps) => {
  useDisablePinchZoom(true)
  useConfigOverrideExpiration(DEFAULT_STORETYPE)
  usePreventContextMenu()

  useEffect(() => {
    const setReturnUrlForCustom = (location: Location) => {
      if (
        !matchPath(location.pathname, {
          path: '/:store_type/:store_id/:lang/custom/rb/:modelCode/:recipeId?',
        })
      ) {
        setReturnUrl(`${location.pathname}${location.search}`)
      }
      if (
        matchPath(location.pathname, {
          path: '/:store_type/:store_id/:lang/custom/rb/',
          exact: true,
        })
      ) {
        setReturnUrl('/')
      }
    }

    history.listen(location => {
      setReturnUrlForCustom(location)
    })
  }, [])
  const defaultKeyboard = getKeyboardLayout() || undefined
  return (
    <VKeyboardProvider
      disabled={!useVKeyboard()}
      defaultSettings={{
        defaultKeyboard: defaultKeyboard,
        hideKeyboardLabel: t('TouchKeyboard.close'),
      }}
    >
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <NavigationProvider>
            <AnalyticsProvider analyticsConfig={config.tealiumAnalytics}>
              <Switch>
                <Route exact path="/service" component={ServicePage} />
                <Route
                  exact
                  path="/config-override"
                  render={() => (
                    <ConfigOverride
                      config={config}
                      defaultConfig={defaultConfig}
                      storeType={DEFAULT_STORETYPE}
                    />
                  )}
                />
                <Route
                  exact
                  path="/config-print"
                  render={() => (
                    <EnviromentInfoPrint processInfo={process.env} storeName={config.name} />
                  )}
                />
                <Route path="/:store_type/:store_id/:lang" component={BaseWrapper} />
                <Route
                  path="/:store_type/:store_id"
                  render={props => <AutofillStoreIdentityParamsWrapper {...props} />}
                />
                <Route
                  path="/:store_type/"
                  render={props => <AutofillStoreIdentityParamsWrapper {...props} />}
                />
                <Route
                  path="/"
                  render={props => <AutofillStoreIdentityParamsWrapper {...props} />}
                />
              </Switch>
            </AnalyticsProvider>
          </NavigationProvider>
        </ConnectedRouter>
      </Provider>
    </VKeyboardProvider>
  )
}

export default translate('translations')(App)
