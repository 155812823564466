import React, { useState, useRef, useEffect, useCallback } from 'react'
import Swiper, { SwiperInstance } from 'react-id-swiper'
import { translate, WithNamespaces } from 'react-i18next'
import styled from 'styled-components'
import { SwiperOptions } from 'swiper'
import { ContentV2Playlist } from 'types/graphql'
import { ProductsSlidesContainer, SliderContainer, Image, SlideTitle } from './styles'
import { isIPadView } from '@abstract/core'
import { useHistory } from 'react-router-dom'
import { useStoreIndentity } from 'hooks/useStoreIdentity'
import { debounce } from 'lodash'
import { RBButton } from 'components/Button'
import 'swiper/css/swiper.css'

const swiperParams: SwiperOptions = {
  loop: true,
  slidesPerView: 5,
  centeredSlides: true,
  spaceBetween: 0,
  coverflowEffect: {
    depth: 350,
    slideShadows: false,
    rotate: 0,
    stretch: 160,
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: false,
    dynamicBullets: true,
  },
  effect: 'coverflow',
}

interface SliderProps extends WithNamespaces {
  playlist: ContentV2Playlist[]
  playlistIndex: number
}

export const SliderWithoutI18n: React.FC<SliderProps> = ({ playlist, playlistIndex, t }) => {
  const [currentSlide, setCurrentSlide] = useState(playlistIndex || 0)
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const swiper = useRef<SwiperInstance>(null)
  const history = useHistory()
  const { basePath } = useStoreIndentity()
  const setSwiper = (swiperInstance: SwiperInstance) => {
    swiper.current = swiperInstance
  }

  const setPlaylist = useCallback(
    (playlistId: string) => {
      const url = `${basePath}/frame-advisor/playlist/${playlistId}`
      history.replace(url)
    },
    [history, basePath]
  )

  const setPopup = useCallback(
    debounce(() => {
      setShowPopup(currentSlide !== playlistIndex)
    }, 800),
    [currentSlide, playlistIndex]
  )

  const onHandleClick = useCallback(() => {
    setPlaylist(playlist[currentSlide].id)
    setShowPopup(false)
  }, [currentSlide, playlist, setPlaylist])

  useEffect(() => {
    if (swiper.current) {
      const setSlide = () => {
        if (!swiper.current) return
        const { realIndex, el, pagination } = swiper.current
        const { classList } = el
        const length = pagination?.bullets.length

        setCurrentSlide(realIndex)

        if (!length) return
        classList.toggle('swiper-container-first', realIndex === 0)
        classList.toggle('swiper-container-second', realIndex === 1)
        classList.toggle('swiper-container-penultimate', realIndex === length - 2)
        classList.toggle('swiper-container-last', realIndex === length - 1)
      }

      setSlide()
      swiper.current.on('slideChange', setSlide)
    }
  }, [swiper])

  useEffect(() => {
    setPopup()
  }, [currentSlide, setPopup])

  const carousel = playlist.map(item => (isIPadView() ? item.carouselIpad[0] : item.carousel[0]))
  const FrameAdvisorSuggestionPopup = styled.div`
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-top: 25.318rem;
    padding-top: 2.909rem;
    display: flex;
    justify-content: center;
    height: calc(100vh - 25.318rem);
    background-color: rgba(0, 0, 0, 0.5);
    @media screen and (max-width: 1024px) {
      margin-top: 24rem;
      height: calc(100vh - 24rem);
    }
  `

  const ShowProductsBtn = styled(RBButton)`
    color: ${({ theme }) => theme.colors.emperor};
    background-color: ${({ theme }) => theme.colors.white};
  `

  return (
    carousel && (
      <ProductsSlidesContainer>
        <SlideTitle>{playlist[currentSlide].title}</SlideTitle>
        <SliderContainer>
          <Swiper {...swiperParams} initialSlide={playlistIndex} getSwiper={setSwiper}>
            {carousel.map((slide, index) => {
              return <Image key={index} src={slide.src} alt={slide.altText || ''} />
            })}
          </Swiper>
        </SliderContainer>
        {showPopup && (
          <FrameAdvisorSuggestionPopup>
            <ShowProductsBtn
              type="button"
              onClick={onHandleClick}
              data-element-id="PlayList_Choose"
              data-description={playlist[currentSlide].title}
            >
              {t('FrameAdvisor.showProducts')}
            </ShowProductsBtn>
          </FrameAdvisorSuggestionPopup>
        )}
      </ProductsSlidesContainer>
    )
  )
}

export default React.memo(translate('translation')(SliderWithoutI18n))
