import { getLangFromUrl } from '@abstract/core'
import { getTranslations } from 'data'
import { DEFAULT_LANG } from 'hooks/useStoreIdentity'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpBackend from 'i18next-http-backend'
import { reactI18nextModule } from 'react-i18next'

const customLangDetector = {
  name: 'dw-lang-detector',
  lookup() {
    const lang = getLangFromUrl()
    return lang
  },
}
const lngDetector = new LanguageDetector()
lngDetector.addDetector(customLangDetector)



i18n
  .use(HttpBackend)
  .use(lngDetector)
  .use(reactI18nextModule)
  .init({
    load: 'currentOnly',
    fallbackLng: DEFAULT_LANG,
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    debug: false,
    detection: {
      order: ['dw-lang-detector'],
      caches: [], // ['localStorage'],
    },
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      wait: true,
    },
    backend: {
      loadPath: '{{lng}}',
      async request(_: any, lang: string, payload: any, callback: any) {
        const {
          data: { translations },
          error,
        } = await getTranslations(lang)
        callback(error, {
          status: error ? 500 : 200,
          data: translations,
        })
      },
    },
  })

export default i18n
