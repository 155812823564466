import { SET_POS, KeyboardActionTypes } from '../actions/keyboard'
import { Point } from 'types/common'

export interface KeyboardState {
  coords: Point
}

const initialState = {
  coords: {
    x: 0,
    y: 0,
  },
}

export default (state = initialState, action: KeyboardActionTypes): KeyboardState => {
  switch (action.type) {
    case SET_POS: {
      const { x, y } = action.payload
      if (!isNaN(x) && !isNaN(y)) {
        state.coords = {
          x,
          y,
        }
      }
      return {
        coords: state.coords,
      }
    }
    default:
      return state
  }
}
