
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FrameAdvisorEvidences"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Product"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"frameAdvisorEvidences"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"frameShape"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"frameTempleThickness"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lensColorTreatment"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"frameColorPalette"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":161}};
    doc.loc.source = {"body":"fragment FrameAdvisorEvidences on Product {\n  frameAdvisorEvidences {\n    frameShape\n    frameTempleThickness\n    lensColorTreatment\n    frameColorPalette\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
