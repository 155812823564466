export const TOGGLE_MENU_DRAWER = 'ui/TOGGLE_MENU_DRAWER'
export const TOGGLE_PDP_CUSTOMIZE_MODAL = 'ui/TOGGLE_PDP_CUSTOMIZE_MODAL'
export const TOGGLE_FILTERS_DRAWER = 'ui/TOGGLE_FILTERS_DRAWER'
export const HIDE_ALL_DRAWER = 'ui/HIDE_ALL_DRAWER'
export const TOGGLE_MINICART_DRAWER = 'ui/TOGGLE_MINICART_DRAWER'
export const TOGGLE_SEARCH_DRAWER = 'ui/TOGGLE_SEARCH_DRAWER'
export const TOGGLE_PDP_INFO_DRAWER = 'ui/TOGGLE_PDP_INFO_DRAWER'
export const TOGGLE_LANGUAGE_SELECTOR = 'ui/TOGGLE_LANGUAGE_SELECTOR'
export const TOGGLE_FRAME_ADVISOR_MYSELECTION = 'ui/TOGGLE_FRAME_ADVISOR_MYSELECTION'
export const TOGGLE_SIMILAR_STYLE = 'ui/TOGGLE_SIMILAR_STYLE'
export const SET_STELLA_HERO_SLIDE_ACTIVE_STATE = 'ui/SET_STELLA_HERO_SLIDE_ACTIVE_STATE'
export const SET_HAS_CHANGED_PAGE = 'ui/SET_HAS_CHANGED_PAGE'
export const TOGGLE_FRAME_ADVISOR_MYSELECTION_ANIMATION =
  'ui/TOGGLE_FRAME_ADVISOR_MYSELECTION_ANIMATION'
export const TOGGLE_AVAILABLE_IN_STORE = 'ui/TOGGLE_AVAILABLE_IN_STORE'
export const UPDATE_PRESELECTED_FILTERS = 'ui/UPDATE_PRESELECTED_FILTERS'
export const SET_DISCOUNT_BANNER_VISIBILITY = 'ui/SET_DISCOUNT_BANNER_VISIBILITY'

export interface ToggleMenuDrawerAction {
  type: typeof TOGGLE_MENU_DRAWER
}

export interface StellaHeroSlideActiveAction {
  type: typeof SET_STELLA_HERO_SLIDE_ACTIVE_STATE
  payload: {
    isActive: boolean
  }
}

export interface TogglePDPCustomizeModalAction {
  type: typeof TOGGLE_PDP_CUSTOMIZE_MODAL
}

export interface ToggleMiniCartDrawerAction {
  type: typeof TOGGLE_MINICART_DRAWER
}
export interface ToggleFiltersDrawerAction {
  type: typeof TOGGLE_FILTERS_DRAWER
}

export interface ToggleSearchDrawerAction {
  type: typeof TOGGLE_SEARCH_DRAWER
}

export interface TogglePdpInfosDrawerAction {
  type: typeof TOGGLE_PDP_INFO_DRAWER
}

export interface ToggleLanguageSelectorAction {
  type: typeof TOGGLE_LANGUAGE_SELECTOR
}

export interface ToggleFrameAdvisorMySelectionAction {
  type: typeof TOGGLE_FRAME_ADVISOR_MYSELECTION
}

export interface ToggleSimilarStyleAction {
  type: typeof TOGGLE_SIMILAR_STYLE
}

export interface HideAllDrawerAction {
  type: typeof HIDE_ALL_DRAWER
}

export interface SetHasChangedPageAction {
  type: typeof SET_HAS_CHANGED_PAGE
}

export interface SetDiscountBannerVisibility {
  type: typeof SET_DISCOUNT_BANNER_VISIBILITY
  payload: {
    isVisible: boolean
  }
}

export interface ToggleAvailableInStore {
  type: typeof TOGGLE_AVAILABLE_IN_STORE
  payload: {
    productType: string
  }
}

export const togglePDPCustomizeModal: TogglePDPCustomizeModalAction = {
  type: TOGGLE_PDP_CUSTOMIZE_MODAL,
}

export interface UpdatePreselectedFilters {
  type: typeof UPDATE_PRESELECTED_FILTERS
  payload: {
    shouldUpdatePreselectedFilters: boolean
  }
}

export const toggleMenuDrawer: ToggleMenuDrawerAction = {
  type: TOGGLE_MENU_DRAWER,
}

export const toggleMiniCartDrawer: ToggleMiniCartDrawerAction = {
  type: TOGGLE_MINICART_DRAWER,
}

export const toggleFiltersDrawer: ToggleFiltersDrawerAction = {
  type: TOGGLE_FILTERS_DRAWER,
}

export const toggleSearchDrawer: ToggleSearchDrawerAction = {
  type: TOGGLE_SEARCH_DRAWER,
}

export const togglePdpInfosDrawer: TogglePdpInfosDrawerAction = {
  type: TOGGLE_PDP_INFO_DRAWER,
}

export const toggleLanguageSelectorList: ToggleLanguageSelectorAction = {
  type: TOGGLE_LANGUAGE_SELECTOR,
}

export const toggleFrameAdvisorMySelectionAction: ToggleFrameAdvisorMySelectionAction = {
  type: TOGGLE_FRAME_ADVISOR_MYSELECTION,
}

export const toggleSimilarStyleAction: ToggleSimilarStyleAction = {
  type: TOGGLE_SIMILAR_STYLE,
}

export const hideAllDrawer: HideAllDrawerAction = {
  type: HIDE_ALL_DRAWER,
}

export const setHasChangedPage: SetHasChangedPageAction = {
  type: SET_HAS_CHANGED_PAGE,
}

export const toggleAvailableInStore = (productType: string): ToggleAvailableInStore => ({
  type: TOGGLE_AVAILABLE_IN_STORE,
  payload: { productType },
})

export const updatePreselectedFilters = (
  shouldUpdatePreselectedFilters: boolean
): UpdatePreselectedFilters => ({
  type: UPDATE_PRESELECTED_FILTERS,
  payload: {
    shouldUpdatePreselectedFilters,
  },
})

export const setStellaHeroSlideStateAction = (isActive: boolean): StellaHeroSlideActiveAction => ({
  type: SET_STELLA_HERO_SLIDE_ACTIVE_STATE,
  payload: {
    isActive,
  },
})

export const setDiscountBannerVisibility = (visibility: boolean): SetDiscountBannerVisibility => {
  return {
    type: SET_DISCOUNT_BANNER_VISIBILITY,
    payload: {
      isVisible: visibility,
    },
  }
}

export type UIActionTypes =
  | ToggleMenuDrawerAction
  | ToggleFiltersDrawerAction
  | ToggleMiniCartDrawerAction
  | ToggleSearchDrawerAction
  | ToggleLanguageSelectorAction
  | TogglePdpInfosDrawerAction
  | ToggleFrameAdvisorMySelectionAction
  | ToggleSimilarStyleAction
  | HideAllDrawerAction
  | SetHasChangedPageAction
  | ToggleAvailableInStore
  | UpdatePreselectedFilters
  | TogglePDPCustomizeModalAction
  | StellaHeroSlideActiveAction
  | SetDiscountBannerVisibility
