import React, { useEffect } from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { useStoreIndentity } from 'hooks/useStoreIdentity'
import { useSelector, useDispatch } from 'react-redux'
import { setHasChangedPage } from 'actions/ui'
import { setCustomerOrder } from 'actions/customerOrder'
import { getCustomerOrderInfo } from 'libs/url'

const BackBtnActions = () => {
  const hasChangedPage = useSelector(s => s.ui.hasChangedPage)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!hasChangedPage) {
      dispatch(setHasChangedPage)
    }
  }, [dispatch, hasChangedPage])

  return <></>
}

const CustomerOrderActions = () => {
  const location = useLocation()
  const { salesOrderId, orderType } = useSelector(s => s.customerOrder)
  const {
    salesOrderId: salesOrderIdFromUrl,
    upc,
    orderType: orderTypeFromUrl,
    email,
  } = getCustomerOrderInfo(location)
  const dispatch = useDispatch()
  const { basePath } = useStoreIndentity()
  const isNewParams = salesOrderIdFromUrl !== salesOrderId || orderTypeFromUrl !== orderType
  useEffect(() => {
    if (salesOrderIdFromUrl && isNewParams) {
      dispatch(setCustomerOrder(salesOrderIdFromUrl, orderTypeFromUrl, email))
    }
  }, [dispatch, salesOrderIdFromUrl, isNewParams, orderTypeFromUrl, email])

  return upc ? <Redirect to={`${basePath}?q=${upc}&fromCustomerOrder=true`} /> : <></>
}

export const AppRouteActions = () => {
  const { basePath } = useStoreIndentity()
  return (
    <>
      <Route path={`${basePath}/.+`}>
        <BackBtnActions />
      </Route>
      <Route path={`${basePath}`}>
        <CustomerOrderActions />
      </Route>
    </>
  )
}
