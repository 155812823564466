import React from 'react'
import { useNoResultsData } from 'data'
import { useStoreIndentity } from 'hooks/useStoreIdentity'
import styled from 'styled-components'
import { RBButton } from 'components/Button'
import { Store } from 'types/store'
import { ProductList, ProductListProps } from 'components/ProductsList'
import { translate, WithNamespaces } from 'react-i18next'
import Loading from 'components/Loading'
import { useHistory } from 'react-router-dom'
import { hideAllDrawer as hideAllDrawerAction } from 'actions/ui'
import { useAction, usePreviousNonNullish } from '@abstract/core'
import { isEmpty } from 'lodash'
import { useFormatReqProductData } from 'hooks/useFormatReqProductData'
import config from 'config'
import { useSelector } from 'react-redux'
import { Product } from 'types/product'
import { UIState } from 'reducers/ui'
import { ProductTypeValues } from 'types/filter'
import { isAvailableInStore } from '../SearchDrawer/SearchProducts'

const NUMBER_OF_PREVIEW_ITEMS = 2

const filterByAvailableInStore = (availableInStore: UIState['availableInStore']) => (
  item: Product
) => {
  if (!item.productType) {
    return true
  }
  const productType = item.productType as ProductTypeValues
  return isAvailableInStore(availableInStore[productType])(item)
}

const NotFoundMessage = styled.p`
  padding: 3em;
  width: 100%;
  font-size: 1rem;
  text-align: center;
`
const Title = styled.p`
  width: 100%;
  font-size: 1.375rem;
  text-align: center;
`

const ContinueShoppingBtn = styled.div`
  text-align: center;
`

interface NoDataSuggestionListProps extends ProductListProps {
  title: string
}

export const NoDataSuggestionList: React.FC<NoDataSuggestionListProps> = ({
  items,
  store,
  title,
}) => {
  return (
    <>
      <Title>{title}</Title>
      <ProductList items={items} store={store} isSearchList />
    </>
  )
}

interface NoDataContentProps extends WithNamespaces {
  store: Store
  fromBarcode?: boolean
  productTypeFilterValue?: string
}

export const NoDataContentWithoutI18N: React.FC<NoDataContentProps> = ({
  store,
  fromBarcode,
  productTypeFilterValue,
  t,
}) => {
  const { basePath } = useStoreIndentity()
  const history = useHistory()
  const hideAllDrawer = useAction(hideAllDrawerAction)
  const productPageUrl = `${basePath}/products`

  const availableInStore = useSelector(state => state.ui.availableInStore)

  const productTypeFilter: Record<string, string[]> = {
    productType: [productTypeFilterValue || config.defaultProductType],
  }

  const { data: queryParamsData } = useFormatReqProductData(
    store,
    {
      ...productTypeFilter,
    },
    NUMBER_OF_PREVIEW_ITEMS
  )

  const { data: noResultsData, loading: noResultsDataLoading } = useNoResultsData(queryParamsData)
  const prevData = usePreviousNonNullish(noResultsData)
  const existingNoResultsData = !isEmpty(noResultsData) && noResultsData ? noResultsData : prevData

  if (noResultsDataLoading) {
    return <Loading />
  }

  const notFoundMessage = fromBarcode
    ? t('ProductsListPage.noBarcodeResults')
    : t('ProductsListPage.noResults')
  return (
    <>
      <NotFoundMessage>{notFoundMessage}</NotFoundMessage>
      {existingNoResultsData?.noResults.noResults.length ? (
        existingNoResultsData?.noResults.noResults.map(
          (carousel: { id: string; products: Product[] }, i: number) => {
            const noResultsItems =
              (carousel.products.length &&
                carousel.products.filter(filterByAvailableInStore(availableInStore))) ||
              []
            return (
              noResultsItems.length >= 1 && (
                <NoDataSuggestionList
                  key={i}
                  items={noResultsItems}
                  store={store}
                  title={t(`NoData.${carousel.id.toLowerCase()}`)}
                />
              )
            )
          }
        )
      ) : (
        <ContinueShoppingBtn>
          <RBButton
            onClick={() => {
              history.push(productPageUrl)
              hideAllDrawer()
            }}
            variant="outlined"
          >
            {t('NoData.continueShoppingButton')}
          </RBButton>
        </ContinueShoppingBtn>
      )}
    </>
  )
}

export const NoDataContent = translate('translation')(NoDataContentWithoutI18N)
