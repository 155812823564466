import styled, { DefaultTheme, css } from 'styled-components'
import { theme } from '../theme'

export interface DataButtonAttrs {
  id?: string
  description?: string
}
interface AriaButtonAttrs {
  label?: string
  description?: string
  disabled?: boolean
}

export interface RoundButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  variant?: 'contained' | 'outlined'
  size?: 'small' | 'medium' | 'medium-large' | 'large'
  disabled?: boolean
  theme?: DefaultTheme
  dataAttrs?: DataButtonAttrs
  ariaAttrs?: AriaButtonAttrs
}

export const variantMap = (
  theme: DefaultTheme,
  variant: RoundButtonProps['variant'] = 'contained',
  disabled?: boolean | undefined
) =>
  ({
    contained: {
      background: disabled
        ? theme.elementsNewUi.roundbutton.containedDisabledBackGround
        : theme.elementsNewUi.roundbutton.containedBackGround,
      color: theme.elementsNewUi.roundbutton.containedColor,
      borderColor: theme.elementsNewUi.roundbutton.containedBorderColor,
    },
    outlined: {
      backround: 'transparent',
      color: disabled
        ? theme.elementsNewUi.roundbutton.outLinedDisabledColor
        : theme.elementsNewUi.roundbutton.outLinedColor,
      borderColor: theme.elementsNewUi.roundbutton.outLinedBorderColor,
      borderSize: theme.elementsNewUi.roundbutton.outLinedBorderSize,
    },
  }[variant])

export const sizeMap = (variant: RoundButtonProps['size'] = 'medium') =>
  ({
    small: {
      height: theme.elementsNewUi.roundbutton.smallHeight,
      fontSize: theme.elementsNewUi.roundbutton.smallFontSize,
    },
    medium: {
      height: theme.elementsNewUi.roundbutton.mediumHeight,
      fontSize: theme.elementsNewUi.roundbutton.mediumFontSize,
    },
    'medium-large': {
      height: theme.elementsNewUi.roundbutton.mediumLargeHeight,
      fontSize: theme.elementsNewUi.roundbutton.mediumLargeFontSize,
    },
    large: {
      height: theme.elementsNewUi.roundbutton.largeHeight,
      fontSize: theme.elementsNewUi.roundbutton.largeFontSize,
    },
  }[variant])

export const ButtonStyle = css<RoundButtonProps>`
  padding: 0 1.37em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: ${({ size }) => sizeMap(size).height};
  border: 1px solid
    ${({ theme, variant, disabled }) => variantMap(theme, variant, disabled)?.borderColor};
  border-radius: 0;
  font-family: ${({ theme }) => theme.fonts.ctaText};
  font-size: ${({ size }) => sizeMap(size).fontSize};
  font-weight: normal;
  color: ${({ theme, variant, disabled }) => variantMap(theme, variant, disabled).color};
  letter-spacing: 0.1px;
  line-height: 1;
  text-align: inherit;
  text-transform: uppercase;
  background: ${({ theme, variant, disabled }) => variantMap(theme, variant, disabled).background};
`

export const RBButton = styled.button<RoundButtonProps>`
  ${ButtonStyle}
`

export const RoundAnchor = styled.a<RoundButtonProps>`
  ${ButtonStyle}
`
