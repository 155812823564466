import { graphqlTypes, SessionState } from '@abstract/core'
import { Availability } from '@abstract/core/dist/types/types/graphqlTypes'
import { FetchResult, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { QueryControls } from '@apollo/client/react/hoc'
import config from 'config'
import { Location } from 'history'
import { useCustomerOrder } from 'hooks/useCustomOrder'
import {
  DEFAULT_LANG,
  DEFAULT_STORE,
  DEFAULT_STORETYPE,
  useStoreIndentity,
} from 'hooks/useStoreIdentity'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppState } from 'store'
import { AboutThisStyle } from 'types/aboutThisStyle'
import { StockItem } from 'types/cart'
import { DiscountBanner } from 'types/discountBanner'
import { FilterFacet } from 'types/filter'
import { FrameAdvisorSurvey, FrameAdvisorVideoProfile } from 'types/frameAdvisor'
import { ContentV2Playlist, ContentV2Video } from 'types/graphql'
import { Image } from 'types/image'
import { PrivacyPolicy } from 'types/privacyPolicy'
import { Product } from 'types/product'
import { Store } from 'types/store'
import { getClient } from './apolloClient'
import ABOUT_THIS_STYLE_QUERY from './graphql/AboutThisStyle.graphql'
import DISCOUNT_BANNER_QUERY from './graphql/DiscountBanner.graphql'
import ENFORCEMENT_MESSAGES_QUERY from './graphql/EnforcementMessages.graphql'
import FRAME_ADVISOR_FSA_TOKEN from './graphql/FrameAdvisorFsaToken.graphql'
import FRAME_ADVISOR_FSA_VIDEOID from './graphql/FrameAdvisorFsaVideoId.graphql'
import FRAME_ADVISOR_FSA_VIDEO_PROFILE from './graphql/FrameAdvisorFsaVideoProfile.graphql'
import FRAME_ADVISOR_SUGGESTION_QUERY from './graphql/FrameAdvisorProducts.graphql'
import GREEN_SHIPMENT_BANNER_QUERY from './graphql/GreenShipmentBanner.graphql'
import INSPIRED_PRODUCTS from './graphql/InspiredProducts.graphql'
import CHECK_AVAILABILITY_QUERY from './graphql/mutations/checkAvailability.graphql'
import CHECKOUT_QUERY from './graphql/mutations/checkout.graphql'
import CREATEVMHASH_QUERY from './graphql/mutations/createVMHash.graphql'
import CUSTOMER_ORDER_CHECKOUT_QUERY from './graphql/mutations/customerOrderCheckout.graphql'
import FRAME_ADVISOR_FSA_CONSENT from './graphql/mutations/frameAdvisorFsaConsent.graphql'
import FRAME_ADVISOR_FSA_EMAIL from './graphql/mutations/frameAdvisorFsaEmail.graphql'
import NO_RESULTS_QUERY from './graphql/NoResults.graphql'
import PAGE_QUERY from './graphql/Page.graphql'
import PRIVACY_POLICY_QUERY from './graphql/PrivacyPolicy.graphql'
import PRODUCTS_QUERY from './graphql/Products.graphql'
import STORE_QUERY from './graphql/Store.graphql'
import TRANSLATIONS_QUERY from './graphql/Translations.graphql'
import { GreenShipmentBanner } from './types/greenShipmentBanner'

const apolloClient = getClient()

export interface HocGraphqlProps {
  location: Location
  state: AppState
  session: SessionState
}

export type PageTypes =
  | 'PageHome'
  | 'PagePDP'
  | 'PageFrameAdvisorPlaylist'
  | 'PageFrameAdvisorSurvey'
  | 'PageStella'

export interface StoreDataQueryResult {
  store: Store
}

export interface PageElementLink {
  url: string
  label: string
}

export interface PageElement {
  id: string
  title: string
  link: PageElementLink
  image: Image
  __typename?: string
}

export interface PageDataQueryResult extends QueryControls {
  page: {
    content: PageElement[]
  }
}

interface GreenShipmentBannerResultData {
  greenShipment: GreenShipmentBanner
}

export interface ProductDataQueryResult {
  products: {
    __typename?: string
    items: Product[]
    numRows: number
    filters: FilterFacet[]
  }
}

export interface PDPDataQueryResult {
  page: {
    product: {
      __typename?: string
    } & Product
    alternatives: Product[]
    tryLenses: graphqlTypes.TryLenses
  }
}
// export interface ProductsDataQueryResult extends QueryControls {}

interface NoResultCarousels {
  products: Product[]
  id: string
}

export interface NoResultsDataQueryResult {
  noResults: {
    __typename: string
    noResults: NoResultCarousels[]
  }
}

interface PrivacyPolicyResultData {
  privacyPolicy: PrivacyPolicy
}

export interface FrameAdvisorPlaylistQueryResult {
  page: {
    id: string
    __typename: string
    playlist: {
      title: string
      playlist: ContentV2Playlist[]
    }
  }
}

export interface FrameAdvisorSurveyQueryResult {
  page: {
    id: string
    __typename: string
    survey: {
      survey: FrameAdvisorSurvey
    }
    videoBg: ContentV2Video[]
  }
}

export interface StoreQueryProps {
  storeData: StoreDataQueryResult & QueryControls
}

export interface PageQueryProps {
  pageData: PageDataQueryResult
}
export interface ProductsQueryProps {
  productsData: ProductDataQueryResult & QueryControls
}

interface StoreDataVariables {
  url: string
  disabledCustomizers?: string[]
  isCompletePair?: boolean
}

export interface StellaDataQueryResult {
  page: {
    __typename?: string
    id: string
    content: graphqlTypes.ContentV2Stella[]
  }
}

interface AboutThisStyleResultData {
  aboutThisStyle: AboutThisStyle
}

interface TranslationsResultData {
  translations: string
}

interface DiscountBannerResultData {
  discountBanner: DiscountBanner
}

export const getTranslations = (lang?: string) => {
  return apolloClient.query<TranslationsResultData>({
    query: TRANSLATIONS_QUERY,
    variables: {
      lang,
      ns: 'rayban',
      region: config.shopperRegion,
    },
    fetchPolicy: 'cache-first',
  })
}

export const useStoreData = () => {
  const isCustomerOrder = useSelector(s => !!s.customerOrder.salesOrderId)
  const { isCompletePairOrderType } = useCustomerOrder()
  const sessionToken = useSelector(state => state.session.token)

  let { pathname } = location
  const urlparts = pathname.split('/')
  const [, storeType, storeId, storeLang] = urlparts

  const vars: StoreDataVariables = {
    url: `${storeType || DEFAULT_STORETYPE}/${storeId || DEFAULT_STORE}/${
      storeLang || DEFAULT_LANG
    }`,
    isCompletePair: isCompletePairOrderType,
  }

  isCustomerOrder &&
    isCompletePairOrderType &&
    (vars['disabledCustomizers'] = ['rbconfigurator', 'ooconfigurator'])

  return useQuery<StoreDataQueryResult>(STORE_QUERY, {
    variables: vars,
    context: {
      headers: {
        'dw-session-id': sessionToken,
      },
    },
    fetchPolicy: 'cache-first',
  })
}

export const useNoResultsData = <T>(data: T) => {
  const { location } = useHistory()
  const { pathname } = location
  const token = useSelector(s => s.session.token)

  return useQuery<NoResultsDataQueryResult>(NO_RESULTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      url: pathname,
      data,
    },
    context: {
      headers: {
        'dw-session-id': token,
      },
    },
    fetchPolicy: 'network-only',
  })
}

export const useSearchData = <T>(url: string, data: T) => {
  const token = useSelector(s => s.session.token)

  return useQuery<ProductDataQueryResult>(PRODUCTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      url,
      data,
      pictureConfig: config.pictureConfig,
    },
    context: {
      headers: {
        'dw-session-id': token,
      },
    },
    fetchPolicy: 'network-only',
  })
}

export const useProductData = <T>(data: T) => {
  const { location } = useHistory()
  const { pathname } = location
  const token = useSelector(s => s.session.token)

  return useQuery<ProductDataQueryResult>(PRODUCTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      url: pathname,
      pictureConfig: config.pictureConfig,
      data,
    },
    context: {
      headers: {
        'dw-session-id': token,
      },
    },
    fetchPolicy: 'network-only',
  })
}

interface CheckoutError {
  status: number
  statusText: string
  message: string
}

interface CheckoutMutationResultData {
  checkout: {
    orderNumber: string
    posReceiptId: string
    errors: CheckoutError[]
  }
}

interface CheckoutCustomerOrderMutationResultData {
  customerOrderCheckout: {
    orderNumber: string
    posReceiptId: string
    errors: CheckoutError[]
  }
}

interface AvailabilityMutationResultData {
  availability: {
    stockItems: StockItem[]
  }
}

interface FrameAdvisorSuggestionMutationResultData {
  frameAdvisorSuggestions: {
    products: Product[]
    numFound: number
    filters: FilterFacet[]
    __typename: string
  }
}

interface FrameAdvisorFsaToken {
  frameAdvisorFsaToken: {
    frameAdvisorFsaToken: {
      token: string
      postURL: string
    }
    videoBg: ContentV2Video[]
  }
}

interface FrameAdvisorFsaVideoId {
  frameAdvisorFsaVideoId: {
    videoId: string
  }
}

interface FrameAdvisorFsaVideoProfile {
  frameAdvisorFsaVideoProfile: FrameAdvisorVideoProfile
}

interface EnforcementMessagesResultData {
  enforcementMessages: [
    {
      position: string
      message: string
    }
  ]
}

export interface InspiredDataQueryResult {
  inspiredProducts: string[]
}

export const useDoCheckout = () => {
  const token = useSelector(s => s.session.token)

  return useMutation<CheckoutMutationResultData>(CHECKOUT_QUERY, {
    context: {
      headers: {
        'dw-session-id': token,
      },
    },
  })
}

export const useDoCustomerOrderCheckout = () => {
  const token = useSelector(s => s.session.token)

  return useMutation<CheckoutCustomerOrderMutationResultData>(CUSTOMER_ORDER_CHECKOUT_QUERY, {
    context: {
      headers: {
        'dw-session-id': token,
      },
    },
  })
}

export const useCheckAvailability = () => {
  const token = useSelector(s => s.session.token)

  return useMutation<AvailabilityMutationResultData>(CHECK_AVAILABILITY_QUERY, {
    context: {
      headers: {
        'dw-session-id': token,
      },
    },
  })
}

interface CheckAvailabilityPayload {
  availability: Availability
  token: string
}

export const checkAvailability = (
  { availability, token }: CheckAvailabilityPayload,
  timeout = 0
) => {
  return new Promise<FetchResult<AvailabilityMutationResultData>>((resolve, reject) => {
    const abortController = new AbortController()
    if (timeout) {
      setTimeout(() => {
        abortController.abort()
        reject()
      }, timeout)
    }
    apolloClient
      .mutate<AvailabilityMutationResultData>({
        mutation: CHECK_AVAILABILITY_QUERY,
        variables: {
          availability,
        },
        context: {
          headers: {
            'dw-session-id': token,
          },
          fetchOptions: { signal: abortController.signal },
        },
      })
      .then(resolve)
      .catch(reject)
  })
}

export const useVMHash = () => {
  return useMutation(CREATEVMHASH_QUERY)
}

const usePageQueryOptions = (
  forcePathname: string | undefined,
  pageType: string,
  forceSearch?: boolean
) => {
  const { location } = useHistory()
  const token = useSelector(s => s.session.token)
  const { isCompletePairOrderType } = useCustomerOrder()
  const { pathname, search } = location

  return {
    notifyOnNetworkStatusChange: true,
    variables: {
      url: forcePathname ? forcePathname : pathname,
      type: pageType,
      search: forceSearch ? '' : search,
      pictureConfig: config.pictureConfig,
      disabledCustomizers: isCompletePairOrderType ? ['rbconfigurator', 'ooconfigurator'] : [],
      isCompletePair: isCompletePairOrderType,
    },
    context: {
      headers: {
        'dw-session-id': token,
      },
    },
    fetchPolicy: 'cache-first' as const,
  }
}
export const usePageData = <T>(
  pageType: PageTypes,
  forcePathname?: string,
  forceSearch = false
) => {
  const queryOptions = usePageQueryOptions(forcePathname, pageType, forceSearch)
  return useQuery<T>(PAGE_QUERY, queryOptions)
}

export const useLazyPageData = <T>(pageType: PageTypes, forcePathname?: string) => {
  const queryOptions = usePageQueryOptions(forcePathname, pageType)
  return useLazyQuery<T>(PAGE_QUERY, queryOptions)
}

export const useFrameAdvisorSuggestion = <T>(data: T) => {
  const token = useSelector(s => s.session.token)
  const { location } = useHistory()
  const { pathname } = location
  return useQuery<FrameAdvisorSuggestionMutationResultData>(FRAME_ADVISOR_SUGGESTION_QUERY, {
    variables: {
      suggestions: data,
      pictureConfig: config.pictureConfig,
      url: pathname,
    },
    notifyOnNetworkStatusChange: true,
    context: {
      headers: {
        'dw-session-id': token,
      },
    },
    fetchPolicy: 'cache-first',
  })
}

export const useFrameAdvisorFsaToken = () => {
  const token = useSelector(s => s.session.token)
  const { location } = useHistory()
  const { pathname } = location
  const options = useMemo(
    () =>
      ({
        variables: {
          url: pathname,
        },
        context: {
          headers: {
            'dw-session-id': token,
          },
        },
      } as const),
    [token, pathname]
  )

  const queryResult = useQuery<FrameAdvisorFsaToken>(FRAME_ADVISOR_FSA_TOKEN, options)
  const { refetch } = queryResult

  // TODO: this is a workaround due to bugs in apollo client with network only requests
  // we must manualy refetch each time the component mounts
  useEffect(() => {
    refetch()
  }, [refetch])
  return queryResult
}

export const useFrameAdvisorFsaVideoId = (fsaToken: string) => {
  const token = useSelector(s => s.session.token)
  return useLazyQuery<FrameAdvisorFsaVideoId>(FRAME_ADVISOR_FSA_VIDEOID, {
    variables: {
      token: fsaToken,
    },
    context: {
      headers: {
        'dw-session-id': token,
      },
    },
    pollInterval: 2000,
    fetchPolicy: 'network-only',
  })
}

export const useFrameAdvisorFsaVideoProfile = (videoId: string) => {
  const token = useSelector(s => s.session.token)
  return useLazyQuery<FrameAdvisorFsaVideoProfile>(FRAME_ADVISOR_FSA_VIDEO_PROFILE, {
    variables: {
      videoId,
    },
    context: {
      headers: {
        'dw-session-id': token,
      },
    },
    fetchPolicy: 'cache-first',
  })
}

export const useEnforcementMessages = <T>(data: T, forcePathname?: string) => {
  const { location } = useHistory()
  const token = useSelector(s => s.session.token)
  const { pathname } = location

  return useQuery<EnforcementMessagesResultData>(ENFORCEMENT_MESSAGES_QUERY, {
    variables: {
      url: forcePathname ? forcePathname : pathname,
      ...data,
    },
    context: {
      headers: {
        'dw-session-id': token,
      },
    },
    fetchPolicy: 'cache-first',
  })
}

export const useFrameAdvisorFsaConsent = () => {
  const token = useSelector(s => s.session.token)

  return useMutation(FRAME_ADVISOR_FSA_CONSENT, {
    context: {
      headers: {
        'dw-session-id': token,
      },
    },
  })
}

export const useFrameAdvisorFsaEmail = () => {
  const token = useSelector(s => s.session.token)

  return useMutation(FRAME_ADVISOR_FSA_EMAIL, {
    context: {
      headers: {
        'dw-session-id': token,
      },
    },
  })
}

export const useInspiredData = (modelCode: string) => {
  return useQuery<InspiredDataQueryResult>(INSPIRED_PRODUCTS, {
    variables: {
      search: modelCode,
    },
    fetchPolicy: 'cache-first',
  })
}

export const stellaBrandId = 'RW'

export const useAboutThisStyle = (brand: string, model: string, forcePathname?: string) => {
  const { location } = useHistory()
  const token = useSelector(s => s.session.token)
  const { pathname } = location

  return useQuery<AboutThisStyleResultData>(ABOUT_THIS_STYLE_QUERY, {
    variables: {
      url: forcePathname ? forcePathname : pathname,
      brand,
      model,
    },
    context: {
      headers: {
        'dw-session-id': token,
      },
    },
    fetchPolicy: 'cache-first',
  })
}

export const useDiscountBanner = <T>(forcePathname?: string) => {
  const { location } = useHistory()
  const token = useSelector(s => s.session.token)
  const { pathname } = location

  return useQuery<DiscountBannerResultData>(DISCOUNT_BANNER_QUERY, {
    variables: {
      url: forcePathname ? forcePathname : pathname,
    },
    context: {
      headers: {
        'dw-session-id': token,
      },
    },
    fetchPolicy: 'cache-first',
  })
}

export const usePrivacyPolicy = () => {
  const { location } = useHistory()
  const token = useSelector(s => s.session.token)
  const { pathname } = location
  const { basePath } = useStoreIndentity()

  return useLazyQuery<PrivacyPolicyResultData>(PRIVACY_POLICY_QUERY, {
    variables: {
      url: basePath || pathname,
    },
    context: {
      headers: {
        'dw-session-id': token,
      },
    },
    fetchPolicy: 'cache-first',
  })
}

export const useGreenShipment = () => {
  const { basePath } = useStoreIndentity()
  const token = useSelector(s => s.session.token)

  return useQuery<GreenShipmentBannerResultData>(GREEN_SHIPMENT_BANNER_QUERY, {
    variables: {
      url: basePath,
    },
    context: {
      headers: {
        'dw-session-id': token,
      },
    },
    fetchPolicy: 'cache-first',
  })
}
