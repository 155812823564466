
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ShippingMethod"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ShippingMethod"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"translationKey"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"estimations"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"min"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"max"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"fee"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"upc"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"destinationAvailability"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"home"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"store"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":169}};
    doc.loc.source = {"body":"fragment ShippingMethod on ShippingMethod {\n  code\n  translationKey\n  estimations {\n    min\n    max\n  }\n  fee\n  upc\n  destinationAvailability {\n    home\n    store\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
