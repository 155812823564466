import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useAction } from '@abstract/core'
import { getKindFilterValue } from '../libs/url'
import { toggleAvailableInStore } from '../actions/ui'

export const useAvailableInStore = (productType?: string) => {
  const location = useLocation()
  const availableInStore = useSelector(state => state.ui.availableInStore)

  if (!productType) {
    productType = getKindFilterValue(location, 'productType') || ''
  }

  return {
    availableInStore: availableInStore[productType],
    toggleAvailableInStore: useAction(toggleAvailableInStore(productType)),
  }
}
