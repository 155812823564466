import { Location as LocationHistory } from 'history'
import config from 'config'
import { getPLPFiltersFromLocation } from '@abstract/core'
import { CustomerOrderType } from 'types/common'
import { ActiveFilters } from '@abstract/ss-adobe-analytics/dist/types/types'
import { FilterKind } from '../types/filter'
import { resolveQueryParams } from 'hooks/useQueryParams'

export const getPLPSortFromLocation = (location: LocationHistory): ActiveFilters[] | undefined => {
  const { sort } = resolveQueryParams(
    {
      sort: (value, kind) => [{ kind, value: String(value) }],
    },
    location.search
  )

  return sort?.length ? sort : undefined
}

export const getKindFilterValue = (
  location: LocationHistory,
  filterKind: FilterKind
): string | undefined => {
  const activeFilters = getPLPFiltersFromLocation(location)
  const genderFilter = activeFilters && activeFilters.find(el => el.kind === filterKind)
  return genderFilter && (genderFilter.value as string)
}

export const getVMUrl = (hashVM: string) => {
  return `${config.vmURL}?hash=${encodeURIComponent(hashVM)}`
}

interface CustomerOrderInfo {
  salesOrderId?: string
  upc?: string
  orderType?: CustomerOrderType
  email?: string
}

const isCustomerOrderType = (value?: unknown): value is CustomerOrderType =>
  ['COMPLETE_PAIR', 'FRAME'].includes(String(value))

export const getCustomerOrderInfo = (location: LocationHistory): CustomerOrderInfo => {
  return resolveQueryParams<CustomerOrderInfo>(
    {
      salesOrderId: String,
      upc: String,
      orderType: value => (isCustomerOrderType(value) ? value : null),
      email: String,
    },
    location.search
  )
}
