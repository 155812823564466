/* stylelint-disable */

import { css } from 'styled-components'

// prettier-ignore
export const fonts = css`
         /* MorePro-CondBook-regular - latin */
         @font-face {
           font-family: 'MorePro-CondBook';
           src: url(${require('../assets/fonts/more_pro_condensed/morepro-condbook.woff2')})
               format('woff2'),
             url(${require('../assets/fonts/more_pro_condensed/morepro-condbook.woff')})
               format('woff');
           font-weight: normal;
           font-style: normal;
         }
         /* ScoutCond-regular - latin */
         @font-face {
           font-family: 'ScoutCond';
           src: url(${require('../assets/fonts/scout_cond/scout-cond-regular.woff')}) format('woff');
           font-weight: normal;
           font-style: normal;
         }
         /* ScoutCond-light - latin */
         @font-face {
           font-family: 'ScoutCond';
           src: url(${require('../assets/fonts/scout_cond/scout-cond-light.woff')}) format('woff');
           font-weight: 300;
           font-style: normal;
         }
         /* Garamond-italic */
         @font-face {
           font-family: 'garamond-italic';
           src: url(${require('../assets/fonts/engraving/garamond-italic/garamond-italic.ttf')})
             format('ttf');
           font-weight: 300;
           font-style: normal;
         }
         /* Righteous */
         @font-face {
           font-family: 'righteous';
           src: url(${require('../assets/fonts/engraving/righteous/Righteous-Regular.woff2')})
             format('woff2');
           font-weight: 300;
           font-style: normal;
         }
         /* Artely-inks */
         @font-face {
           font-family: 'artely-inks';
           src: url(${require('../assets/fonts/engraving/artely-inks/artely-inks.woff2')})
             format('woff2');
           font-weight: 300;
           font-style: normal;
         }
         /* Nunito Regular */
         @font-face {
          font-family: 'Nunito';
          src: url(${require('../assets/fonts/nunito/regular/Nunito-regular.woff2')}) format('woff2');
          font-weight: normal;
          font-style: normal;
        }
            /* Nunito Light*/
          @font-face {
          font-family: 'Nunito';
          src: url(${require('../assets/fonts/nunito/light/Nunito-light.woff2')}) format('woff2');
          font-weight: 300;
          font-style: normal;
        }
            /* Nunito Heavy*/
          @font-face {
          font-family: 'Nunito';
          src: url(${require('../assets/fonts/nunito/heavy/Nunito-heavy.woff2')}) format('woff2');
          font-weight: 600;
          font-style: normal;
        }
          /* Oswald */
          @font-face {
            font-family: 'Oswald';
            src: url(${require('../assets/fonts/oswald/Oswald-VariableFont_wght.ttf')}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }
          /* Oswald-Medium */
          @font-face {
            font-family: 'Oswald-Medium';
            src: url(${require('../assets/fonts/oswald/Oswald-VariableFont_wght.ttf')}) format('truetype');
            font-weight: 500;
            font-style: normal;
        }
          /* Lato */
          @font-face {
            font-family: 'Lato';
            src: url(${require('../assets/fonts/engraving/lato-regular/lato-regular.woff2')}) format('woff2');
            font-weight: normal;
            font-style: normal;
          }

             /* Lato Light */
             @font-face {
            font-family: 'Lato';
            src: url(${require('../assets/fonts/lato/lato-300.woff2')}) format('woff2');
            font-weight: 300;
            font-style: normal;
          }

             /* Lato Bold */
             @font-face {
            font-family: 'Lato';
            src: url(${require('../assets/fonts/lato/lato-700.woff2')}) format('woff2');
            font-weight: 700;
            font-style: normal;
          }
       `
