/* eslint-disable @typescript-eslint/camelcase */
import React from 'react'

import {
  ItemContainer,
  ProductList,
  CTAContainer,
  ScrollableContainer,
  FrameAdvisorCardProduct,
  StyledFrameAdvisorProductCard,
} from './styles'

import {
  hateItem,
  loveItem,
  removeItemFromMySelection,
  saveToUndoableList,
  removeFromUndoableList,
} from '../../../actions/frameAdvisor'

import { Product } from 'types/product'
import { RBButton } from 'components/Button'
import i18next from 'i18next'
import { useActions } from '@abstract/core'
import { useSelector } from 'react-redux'
import { useSendAnalyticsProductsEvent } from 'containers/AnalyticsProvider'
import { dataDescriptionForProduct } from '@abstract/ss-adobe-analytics'
import { useVMVisibility } from 'hooks/useVMVisibility'

interface FrameAdvisorSuggestions {
  suggestions: { products: Product[]; numFound: number }
  fetchMoreItems: () => void
  t: i18next.TFunction
}

export const FrameAdvisorSuggestions = React.forwardRef<HTMLDivElement, FrameAdvisorSuggestions>(
  ({ suggestions, fetchMoreItems, t }, ref) => {
    const { products, numFound } = suggestions
    const playListId = useSelector(s => s.frameAdvisor.selectedPlaylist?.id)
    const selectedItems = useSelector(state => state.frameAdvisor.mySelection)
    const nItems = products.length
    const hasItems = nItems > 0
    const hasMoreItems = nItems < numFound
    const sendAddToWishlistEvent = useSendAnalyticsProductsEvent({
      id: 'ProdFavAdd',
    })
    const { isVMEnabled } = useVMVisibility()
    const backToTop = () => {
      const container = ref as React.RefObject<HTMLDivElement>
      if (container && container.current) {
        container.current.scrollTop = 0
      }
    }
    const actions = useActions({
      loveItem,
      hateItem,
      removeItemFromMySelection,
      saveToUndoableList,
      removeFromUndoableList,
    })

    if (!hasItems) return null // TODO need smth like noitems component here

    return (
      <ItemContainer id="frameAdvisorScrollableItemContainer" ref={ref}>
        <ScrollableContainer>
          <ProductList>
            {products.map((product, index) => (
              <StyledFrameAdvisorProductCard
                index={index}
                key={product.UPC}
                product={product}
                dataAttrs={{
                  link: `Tiles_Tile${index}-Img`,
                  description: dataDescriptionForProduct(product),
                }}
                onLoveClick={actions.loveItem}
                onHateClick={actions.hateItem}
                saveForUndo={actions.saveToUndoableList}
                handleRemove={actions.removeItemFromMySelection}
                isItemLiked={selectedItems.some(item => item.UPC === product.UPC)}
                playlistId={playListId || ''}
                sendAddToWishlistEvent={sendAddToWishlistEvent}
                cardProduct={
                  <FrameAdvisorCardProduct
                    product={product}
                    additionalQueryStringAttributes={{ fromFrameAdvisor: true }}
                    dataAttrs={{
                      link: `Tiles_Tile${index}-Img`,
                      description: dataDescriptionForProduct(product),
                    }}
                    displayWishlistIconAtBottom
                    vtoEnabled={isVMEnabled}
                  />
                }
              />
            ))}
          </ProductList>
          <CTAContainer>
            <RBButton
              type="button"
              className="back-to-top"
              onClick={backToTop}
              data-element-id="Playlist_BackToTop"
            >
              {t('FrameAdvisor.ctaBackToTop')}
            </RBButton>
            {hasMoreItems && (
              <RBButton type="button" onClick={fetchMoreItems} data-element-id="Playlist_LoadMore">
                {t('FrameAdvisor.ctaLoadMore')}
              </RBButton>
            )}
          </CTAContainer>
        </ScrollableContainer>
      </ItemContainer>
    )
  }
)

FrameAdvisorSuggestions.displayName = 'FrameAdvisorSuggestions'

export default React.memo(FrameAdvisorSuggestions)
