import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/client'

import App from './App'
import { getClient } from './apolloClient'
import * as serviceWorker from './serviceWorker'

import './i18n'
import { GlobalStyle } from 'styles/index'
import { ThemeProvider } from 'styled-components'
import { theme } from 'theme'
import config from './config'

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ApolloProvider client={getClient()}>
      <GlobalStyle />
      <App />
    </ApolloProvider>
  </ThemeProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
config.enableServiceWorker ? serviceWorker.register() : serviceWorker.unregister()
