import { Maybe } from 'graphql/jsutils/Maybe'
import i18next from 'i18next'
import toArray from 'lodash/toArray'
import isString from 'lodash/isString'
import {
  FiltersFromUrl,
  FiltersFromUrlKind,
  FormattedFilterMultiRangeType,
  FormattedFiltersType,
  MultiRangeFiltersKind,
  RangeFiltersKind,
} from '../types/filter'

export const formatCategoryValue = (category: string) => {
  const formattedCategory = category.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''))
  return formattedCategory.substr(0, 1).toLowerCase() + formattedCategory.substr(1)
}

export const isTrueFilter = (filterValue: string | undefined): boolean =>
  ['true', 'TRUE', '1'].includes(filterValue ?? '')

export const isTrueFilterArray = (filterValue: string[] | undefined): boolean =>
  !!filterValue?.every(isTrueFilter)

export const isFilterRange = (filterKind: FiltersFromUrlKind): filterKind is RangeFiltersKind =>
  ['minLensHeight', 'maxLensHeight'].includes(filterKind)

export const isFilterMultiRange = (
  filterKind: FiltersFromUrlKind
): filterKind is MultiRangeFiltersKind => ['maxPrice', 'minPrice'].includes(filterKind)

export const translateFilterValue = (
  filter: Maybe<Array<Maybe<string>> | string>,
  translationKey: string,
  t: i18next.TFunction
): string => {
  if (!filter) {
    return ''
  }

  return (Array.isArray(filter) ? filter : [filter])
    .filter(v => !!v)
    .map(v => t(`PlpFilters${translationKey}.${v?.toLowerCase()}`, { defaultValue: filter }))
    .join(', ')
}

export const formatFilterValue = <T extends FiltersFromUrlKind>(
  filterKind: T,
  filterValue: FiltersFromUrl[T]
): FormattedFiltersType => {
  if (isFilterRange(filterKind)) {
    return Number(filterValue)
  }

  if (isString(filterValue) && isTrueFilter(filterValue)) {
    return true
  }

  if (Array.isArray(filterValue) && isTrueFilterArray(filterValue)) {
    return true
  }

  return filterValue
}

export const formatMultiRangeFilterValue = <T extends MultiRangeFiltersKind>(
  filterKind: MultiRangeFiltersKind,
  filterValue: FiltersFromUrl[T],
  rangeFilterValue: FormattedFilterMultiRangeType[] = []
): FormattedFilterMultiRangeType[] => {
  const rangeArrayValue = toArray(filterValue).map(Number)

  rangeArrayValue.forEach((value, index) => {
    const rangeFilterValueItem = rangeFilterValue[index] ?? {}
    rangeFilterValueItem[filterKind] = value
    rangeFilterValue[index] = rangeFilterValueItem
  })

  return rangeFilterValue
}
