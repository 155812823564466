import {
  UIActionTypes,
  HIDE_ALL_DRAWER,
  TOGGLE_MENU_DRAWER,
  TOGGLE_FILTERS_DRAWER,
  TOGGLE_MINICART_DRAWER,
  TOGGLE_SEARCH_DRAWER,
  TOGGLE_LANGUAGE_SELECTOR,
  TOGGLE_PDP_INFO_DRAWER,
  TOGGLE_FRAME_ADVISOR_MYSELECTION,
  TOGGLE_SIMILAR_STYLE,
  SET_HAS_CHANGED_PAGE,
  TOGGLE_AVAILABLE_IN_STORE,
  UPDATE_PRESELECTED_FILTERS,
  TOGGLE_PDP_CUSTOMIZE_MODAL,
  SET_STELLA_HERO_SLIDE_ACTIVE_STATE,
  SET_DISCOUNT_BANNER_VISIBILITY,
} from 'actions/ui'
import { ProductTypeValues } from 'types/filter'

export interface UIState {
  menuDrawerIsVisible: boolean
  filtersDrawerIsVisible: boolean
  minicartDrawerIsVisible: boolean
  searchDrawerIsVisible: boolean
  languageSelectorIsVisible: boolean
  pdpInfosIsVisible: boolean
  frameAdvisorMySelectionIsVisible: boolean
  similarStyleIsVisible: boolean
  hasChangedPage: boolean
  frameAdvisorMySelectionIsAnimated: boolean
  discountBannerIsVisible: boolean
  availableInStore: Record<string, boolean>
  hasComeFromMenu: boolean
  shouldUpdatePreselectedFilters: boolean
  PDPCustomModal: boolean
  isStellaSlideActive: boolean
}

const initialState: UIState = {
  menuDrawerIsVisible: false,
  filtersDrawerIsVisible: false,
  minicartDrawerIsVisible: false,
  searchDrawerIsVisible: false,
  languageSelectorIsVisible: false,
  pdpInfosIsVisible: false,
  frameAdvisorMySelectionIsVisible: false,
  similarStyleIsVisible: false,
  discountBannerIsVisible: false,
  hasChangedPage: false,
  frameAdvisorMySelectionIsAnimated: false,
  availableInStore: {
    [ProductTypeValues.SUN]: false,
    [ProductTypeValues.OPTICAL]: true,
  },
  hasComeFromMenu: false,
  shouldUpdatePreselectedFilters: false,
  PDPCustomModal: false,
  isStellaSlideActive: false,
}

export default (state = initialState, action: UIActionTypes) => {
  switch (action.type) {
    case TOGGLE_MENU_DRAWER:
      return { ...state, menuDrawerIsVisible: !state.menuDrawerIsVisible }
    case TOGGLE_MINICART_DRAWER:
      return { ...state, minicartDrawerIsVisible: !state.minicartDrawerIsVisible }
    case TOGGLE_FILTERS_DRAWER:
      return { ...state, filtersDrawerIsVisible: !state.filtersDrawerIsVisible }
    case TOGGLE_SEARCH_DRAWER:
      return { ...state, searchDrawerIsVisible: !state.searchDrawerIsVisible }
    case TOGGLE_PDP_INFO_DRAWER:
      return { ...state, pdpInfosIsVisible: !state.pdpInfosIsVisible }
    case TOGGLE_LANGUAGE_SELECTOR:
      return { ...state, languageSelectorIsVisible: !state.languageSelectorIsVisible }
    case TOGGLE_FRAME_ADVISOR_MYSELECTION:
      return {
        ...state,
        frameAdvisorMySelectionIsVisible: !state.frameAdvisorMySelectionIsVisible,
      }
    case TOGGLE_SIMILAR_STYLE:
      return {
        ...state,
        similarStyleIsVisible: !state.similarStyleIsVisible,
      }
    case HIDE_ALL_DRAWER:
      return {
        ...initialState,
        hasChangedPage: state.hasChangedPage,
        availableInStore: state.availableInStore,
        PDPCustomModal: state.PDPCustomModal,
        hasComeFromMenu: state.hasComeFromMenu,
        shouldUpdatePreselectedFilters: state.shouldUpdatePreselectedFilters,
        isStellaSlideActive: state.isStellaSlideActive,
        discountBannerIsVisible: state.discountBannerIsVisible,
      }
    case SET_HAS_CHANGED_PAGE:
      return {
        ...state,
        hasChangedPage: true,
      }
    case TOGGLE_AVAILABLE_IN_STORE:
      return {
        ...state,
        availableInStore: {
          ...state.availableInStore,
          [action.payload.productType]: !state.availableInStore[action.payload.productType],
        },
      }
    case UPDATE_PRESELECTED_FILTERS: {
      return {
        ...state,
        shouldUpdatePreselectedFilters: action.payload.shouldUpdatePreselectedFilters,
      }
    }
    case TOGGLE_PDP_CUSTOMIZE_MODAL:
      return {
        ...state,
        PDPCustomModal: !state.PDPCustomModal,
      }
    case SET_STELLA_HERO_SLIDE_ACTIVE_STATE: {
      return {
        ...state,
        isStellaSlideActive: action.payload.isActive,
      }
    }

    case SET_DISCOUNT_BANNER_VISIBILITY:
      return { ...state, discountBannerIsVisible: action.payload.isVisible }

    default:
      return state
  }
}
