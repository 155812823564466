import {
  getConfigFromSessionStorage,
  getConfigOverrideSessionKey,
} from '@abstract/react-environment-info'

const boolean = (value: string | undefined) => {
  return Boolean(value && value.toLowerCase && value.toLowerCase() === 'true')
}

const safeEnv = (key: string) => {
  const value = process.env[key]
  if (value === undefined) throw new Error(`Missing env variable ${key}`)
  return value
}

export const defaultConfig = {
  name: process.env.NAME,
  version: process.env.VERSION,
  publicUrl: process.env.PUBLIC_URL || '',
  middleware: {
    URL: safeEnv('REACT_APP_GRAPHQL_API_URL'),
  },
  assets: {
    URL: safeEnv('REACT_APP_ASSETS_URL'),
  },
  productImageUrl: safeEnv('REACT_APP_PRODUCT_IMAGE_URL'),
  log2api: true, // used to trace logs to api server.
  logServerUrl: safeEnv('REACT_APP_LOG_SERVER_URL'),
  itemsPerPage: 39,
  itemsPerFrameAdvisor: 12,
  enablePaginationLimit: 15,
  enableServiceWorker: boolean(safeEnv('REACT_APP_ENABLE_SERVICE_WORKER')),
  resetAppTimeOut: 420000, // reset every 7 minutes - use 0 or false to disable.
  minicartDelay: 0, // 0 to disable the auto close closing minicart event
  resetAppOnClickLogo: true, // force reload when user clicks on main logo
  useVKeyboard: true, // use the virtual keyboard or not
  env: 'production',
  vmEnv: safeEnv('REACT_APP_CONFIGURATOR_VM_ENV'),
  vmURL: safeEnv('REACT_APP_VM_URL'),
  vmJS: safeEnv('REACT_APP_VM_JS'),
  vmEnable: boolean(safeEnv('REACT_APP_VM_ENABLE')),
  vmIPadEnable: boolean(safeEnv('REACT_APP_VM_IPAD_ENABLE')),
  enableVMShareButtonOnIpad: true,
  rbCustomEndPoint: safeEnv('REACT_APP_RBCUSTOM_ENDPOINT'),
  rbCustomEndPointV2: safeEnv('REACT_APP_RBCUSTOM_ENDPOINT_V2'),
  rbCustomJsUrl: safeEnv('REACT_APP_RBCUSTOM_JS'),
  rbCustomOcId: safeEnv('REACT_APP_RBCUSTOM_OC_ID'),
  googleMapsApiKey: safeEnv('REACT_APP_GOOGLE_MAPS_API_KEY'),
  vtoImageService: safeEnv('REACT_APP_VTO_IMAGE_SERVICE'),
  vmKey: safeEnv('REACT_APP_VM_KEY'),
  vmSource: safeEnv('REACT_APP_VM_SOURCE'),
  showQRCodeDebugInfo: boolean(safeEnv('REACT_APP_SHOW_QRCODE_DEBUG_INFO')),
  googleApiSupportedLangs: [
    'ar',
    'be',
    'bg',
    'bn',
    'ca',
    'cs',
    'da',
    'de',
    'el',
    'en',
    'en-Au',
    'en-GB',
    'es',
    'eu',
    'fa',
    'fi',
    'fil',
    'fr',
    'gl',
    'gu',
    'hi',
    'hr',
    'hu',
    'id',
    'it',
    'iw',
    'ja',
    'kk',
    'kn',
    'ko',
    'ky',
    'lt',
    'lv',
    'mk',
    'ml',
    'mr',
    'my',
    'nl',
    'no',
    'pa',
    'pl',
    'pt',
    'pt-BR',
    'pt-PT',
    'ro',
    'ru',
    'sk',
    'sl',
    'sq',
    'sr',
    'sv',
    'ta',
    'te',
    'th',
    'tl',
    'tr',
    'uk',
    'uz',
    'vi',
    'zh-CN',
    'zh-TW',
  ],
  tealiumAnalytics: {
    profile: 'smartshopper2',
    shopperSlug: 'rayban',
    gtagId: 'dw-gtag-tealium',
    isEnabled: boolean(safeEnv('REACT_APP_ANALYTICS_ENABLED')),
  },
  isAssortmentProductImage: boolean(process.env.REACT_APP_ASSORTMENT_IMAGES_ENABLE),
  pictureConfig: {
    priority:
      process.env.REACT_APP_PICTURE_CONFIG_PRIORITY &&
      process.env.REACT_APP_PICTURE_CONFIG_PRIORITY.split(','),
    mainAngle: safeEnv('REACT_APP_PICTURE_CONFIG_MAIN_ANGLE'),
  },
  showBadgeNew: boolean(safeEnv('REACT_APP_SHOW_BADGE_NEW')),
  defaultProductType: safeEnv('REACT_APP_DEFAULT_PRODUCT_TYPE'),
  shopperRegion: safeEnv('REACT_APP_SHOPPER_REGION'),
  showDiscount: boolean(safeEnv('REACT_APP_SHOW_DISCOUNT')),
  availabilityTimeout: parseInt(safeEnv('REACT_APP_AVAILABILITY_TIMEOUT')),
}

export type AppConfig = typeof defaultConfig

const configOverrideSessionKey = getConfigOverrideSessionKey('rayban')
const configFromSessionStorage = getConfigFromSessionStorage<AppConfig>(configOverrideSessionKey)
const config: AppConfig = configFromSessionStorage || defaultConfig

export default config
