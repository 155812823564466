import React from 'react'
import { Route } from 'react-router-dom'
import { useStoreIndentity } from 'hooks/useStoreIdentity'
import { Disclaimer as UnstyledDisclaimer } from 'components/Disclaimer'
import styled from 'styled-components'
import { device } from 'styles/variables'

const Disclaimer = styled(UnstyledDisclaimer)`
  bottom: 1.5rem;
`

const DisclaimerWithBackground = styled(UnstyledDisclaimer)`
  bottom: 0;
  padding: 1rem 4.5rem;
  background-color: #fff;

  @media only ${device.ipadMiniPortrait} {
    padding-bottom: 1.5rem;
  }
`

export const AppRouteDisclaimer = () => {
  const { basePath } = useStoreIndentity()
  return (
    <>
      <Route exact path={`${basePath}/custom/rb/:modelCode/:recipeId?`}>
        <Disclaimer />
      </Route>
      <Route exact path={`${basePath}/custom/rb`}>
        <DisclaimerWithBackground />
      </Route>
    </>
  )
}
