import React from 'react'
import searchIcon from 'assets/icons/searchIcon.svg'
import styled from 'styled-components'

const SearchImg = styled.img`
  padding: 0.5rem;
  width: 100%;
  height: 100%;
`

const SearchIcon = () => {
  return <SearchImg alt="search-menu-button" src={searchIcon} />
}

export default SearchIcon
