import { useEffect } from 'react'
import { toggleSearchDrawer } from 'actions/ui'
import { useDispatch } from 'react-redux'
import { BooleanResolver, useQueryParams } from './useQueryParams'

export const useUrlParams = () => {
  const { fromBarcode, fromCustomerOrder } = useQueryParams({
    fromBarcode: BooleanResolver,
    fromCustomerOrder: BooleanResolver,
  })

  return {
    fromBarcode: !!fromBarcode,
    fromCustomerOrder: !!fromCustomerOrder,
  }
}

export const useToggleSearchDrawer = () => {
  const dispatch = useDispatch()
  const { fromBarcode, fromCustomerOrder } = useUrlParams()

  useEffect(() => {
    if (fromBarcode || fromCustomerOrder) {
      dispatch(toggleSearchDrawer)
    }
  }, [dispatch, fromBarcode, fromCustomerOrder])
}
