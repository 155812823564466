import CardProduct from 'components/CardProduct'
import { useSendAnalyticsProductsEvent } from 'containers/AnalyticsProvider'
import { useVMVisibility } from 'hooks/useVMVisibility'
import { isCustomizableEnabled } from 'libs/customProducts'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Product } from 'types/product'
import { Store } from 'types/store'

export interface ProductListProps {
  items: Product[]
  store: Store
  previewQuality?: number
  genderValue?: string
  isSearchList?: boolean
  displayWishlistIconAtBottom?: boolean
}

const ProductListWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
`

const ProductItem = styled.div`
  padding: 1em;
  display: flex;
  flex-basis: 50%;
`

export const ProductList: React.FC<ProductListProps> = ({
  items,
  store,
  previewQuality,
  genderValue,
  isSearchList,
  displayWishlistIconAtBottom = true,
}) => {
  const customerOrderType = useSelector(s => s.customerOrder.orderType)
  const { isVMEnabled } = useVMVisibility()

  const sendAddToWishlistEvent = useSendAnalyticsProductsEvent({
    id: 'ProdFavAdd',
  })
  return (
    <ProductListWrapper>
      {items.map((item, index) => {
        return (
          <ProductItem key={item.UPC}>
            <CardProduct
              customizableEnabled={isCustomizableEnabled(store.rbCustomEnabled, customerOrderType)}
              engravingEnabled={false}
              hidePrice={false}
              product={item}
              showRange
              vtoEnabled={isVMEnabled}
              dataAttrs={{
                link: isSearchList ? `MainNav_SearchTiles_${index}-Img` : `Tiles_${index}-Img`,
                wishlist: `Tiles_${index}-FavAdd`,
              }}
              sendAddToWishlistEvent={sendAddToWishlistEvent}
              previewQuality={previewQuality}
              gender={genderValue}
              displayWishlistIconAtBottom={displayWishlistIconAtBottom}
            />
          </ProductItem>
        )
      })}
    </ProductListWrapper>
  )
}
