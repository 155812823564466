import styled, { css, keyframes } from 'styled-components'
import { device } from 'styles/variables'

const growIconBadge = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
`

export const MenuIconBadge = styled.span<{ isAnimation?: boolean; transparentBg?: boolean }>`
  position: absolute;
  top: 1.136rem;
  right: 1.273rem;
  display: block;
  width: 0.545rem;
  height: 0.545rem;
  border-radius: 100%;
  background-color: ${({ theme, transparentBg }) =>
    transparentBg ? 'transparent' : theme.colors.monza};
  animation: ${({ isAnimation }) =>
    isAnimation &&
    css`
      ${growIconBadge} 1.5s linear
    `};
`

export const FloatingButton = styled.button`
  position: relative;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border: 1px solid ${({ theme }) => theme.colors.concrete};
  border-bottom: none;
  background-color: white;
  img {
    width: 4rem;
    height: 4rem;
  }
`

export const FloatingButtonInner = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export const FloatingContainer = styled.div`
  z-index: 1;
  position: fixed;
  top: 12rem;
  left: 0;

  &.without-back-button {
    margin-top: 4rem;
  }

  ${FloatingButton}:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.concrete};
  }

  @media ${device.ipadPortrait} {
    top: 10.909rem;
  }
`
