import { ThunkAction } from 'redux-thunk'
import { AppState } from 'store'
import { Action } from 'redux'
import { toggleFrameAdvisorMySelectionAction } from 'actions/ui'
import {
  setFrameAdvisorMyselectionVisited,
  updateSurvey,
  setLastSurveyFaceStep,
  resetSurvey,
  setCompleteSurvey,
  loveMoco,
  hateMoco,
  saveMocoForUndo,
  removeMocoFromUndo,
  undoHate,
  removeMoco,
  resetLoveHate,
  updateVisited,
  addToMySelection,
  removeFromMySelection,
  saveFsaVideoId,
  resetFsa,
  toggleCouvetteDressedOn,
  toggleWishlistDressedOn,
  loveItem,
  hateItem,
  saveToUndoableList,
  removeFromUndoableList,
  undoHatedItem,
  removeItemFromMySelection,
  selectFrameAdvisorPlaylist,
  selectFrameAdvisorShades,
  setSelectedOptionId,
} from '@abstract/sss-frame-advisor'

export const toggleFrameAdvisorMySelectionSetVisited = (): FrameAdvisorThunkAction => dispatch => {
  dispatch(toggleFrameAdvisorMySelectionAction)
  dispatch(setFrameAdvisorMyselectionVisited())
}

type FrameAdvisorThunkAction = ThunkAction<void, AppState, unknown, Action<string>>

export {
  setFrameAdvisorMyselectionVisited,
  updateSurvey,
  setLastSurveyFaceStep,
  resetSurvey,
  setCompleteSurvey,
  loveMoco,
  hateMoco,
  saveMocoForUndo,
  removeMocoFromUndo,
  undoHate,
  removeMoco,
  resetLoveHate,
  updateVisited,
  addToMySelection,
  removeFromMySelection,
  saveFsaVideoId,
  resetFsa,
  toggleCouvetteDressedOn,
  toggleWishlistDressedOn,
  loveItem,
  hateItem,
  saveToUndoableList,
  removeFromUndoableList,
  undoHatedItem,
  removeItemFromMySelection,
  selectFrameAdvisorPlaylist,
  selectFrameAdvisorShades,
  setSelectedOptionId,
}
