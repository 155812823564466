import { get } from 'lodash'
import { getThumbnailUrl, RBThumbnailSide, OOThumbnailSide } from '@abstract/one-configurator'
import { CustomProduct } from 'types/product'
import { Maybe } from 'graphql/jsutils/Maybe'

export const isCustomizableEnabled = (
  rbCustomEnabled: boolean,
  customerOrderType?: string | null
): boolean => {
  if (customerOrderType && customerOrderType === 'COMPLETE_PAIR') {
    return false
  }
  return rbCustomEnabled
}

export const getThumbnailUrlForCustom = (
  recipeId: Maybe<number> | string,
  brand: string,
  scale?: number,
  backgroundColor?: string
) => {
  const composerService = 'https://cdn-prod.fluidconfigure.com/imagecomposer'
  const sideCode = brand === 'RB' ? RBThumbnailSide.FrontLeft : OOThumbnailSide.Left

  return getThumbnailUrl(
    Number(recipeId),
    scale,
    sideCode,
    composerService,
    backgroundColor || '#f6f6f6',
    'png'
  )
}

export const formatOcpPayloadField = (product: CustomProduct) => {
  // Bom is defined when we use OneConfigurator

  const bom = get(product, 'customItem.custom.bom')
  if (!bom) return product.customItem

  return {
    custom: {
      bom,
    },
  }
}
