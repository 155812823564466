import {
  FiltersFromUrl,
  FilterOption,
  FilterFacet,
  FiltersState,
  RangeFiltersKind,
  ProductTypeValues,
  DefaultFilters,
  DefaultFilter,
  FilterRange,
  FilterKind,
  DEFAULT_FILTERS,
  MultiRangeFiltersKind,
  FiltersFromUrlKind,
  FormattedFilterMultiRangeType,
  FormattedFilters,
  FormattedFiltersType,
} from 'types/filter'
import { useHistory, useLocation } from 'react-router-dom'
import { History } from 'history'
import { useMemo, useCallback, useState, useRef, useEffect } from 'react'
import qs from 'qs'
import groupBy from 'lodash/groupBy'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'
import { executeOnce, getPLPFiltersFromLocation, useActions, usePrevious } from '@abstract/core'
import { getKindFilterValue } from 'libs/url'
import { useSelector } from 'react-redux'
import { CustomerOrderType } from 'types/common'
import { uniqueId } from 'libs/utils'
import { isFilterMultiRange, formatFilterValue, formatMultiRangeFilterValue } from 'libs/filters'
import { ActiveFilters } from '@abstract/ss-adobe-analytics/dist/types/types'
import { useAvailableInStore } from './useAvailableInStore'
import { updatePreselectedFilters } from 'actions/ui'
import config from 'config'
import { objectKeys } from 'libs/object'

const isInArrayOrEqual = <T>(arrayOrValue: T | T[], value: T) => {
  if (Array.isArray(arrayOrValue)) {
    return arrayOrValue.includes(value)
  }
  return arrayOrValue === value
}

const toArray = <T>(arrayOrValue: T | T[] | undefined) => {
  return arrayOrValue ? ([] as T[]).concat(arrayOrValue) : []
}

const applyFilters = (filtersFromUrl: FiltersFromUrl, history: History) => {
  const newSearch = qs.stringify(filtersFromUrl)
  history.replace({ ...location, search: newSearch })
}

export const useFiltersFromUrl = () => {
  const {
    location: { search },
  } = useHistory()
  return useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]) as FiltersFromUrl
}

export const useFiltersFromRedux = () => {
  const { availableInStore } = useAvailableInStore()

  return useMemo(() => {
    const filters: FiltersFromUrl = {}

    if (availableInStore) {
      filters.storeAvailable = [String(Number(availableInStore))]
    }

    return filters
  }, [availableInStore])
}

export const useToggleUrlFilter = (kind: keyof FiltersFromUrl, value: string, replace = false) => {
  const history = useHistory()

  return useCallback(() => {
    const {
      location: { search },
    } = history

    const filtersFromUrl = qs.parse(search, { ignoreQueryPrefix: true }) as FiltersFromUrl
    const valuesFromUrl = toArray(filtersFromUrl[kind])

    if (valuesFromUrl.includes(value)) {
      return applyFilters(
        {
          ...filtersFromUrl,
          [kind]: replace ? undefined : valuesFromUrl.filter(v => v !== value),
        },
        history
      )
    }

    return applyFilters(
      {
        ...filtersFromUrl,
        [kind]: replace ? value : [...valuesFromUrl, value],
      },
      history
    )
  }, [kind, value, history, replace])
}

export const useToggleRange = (
  filterRange: FilterRange,
  minKind: RangeFiltersKind | MultiRangeFiltersKind,
  maxKind: RangeFiltersKind | MultiRangeFiltersKind
) => {
  const minKindValue = filterRange.min.toString()
  const maxKindValue = filterRange.max.toString()

  const history = useHistory()

  return useCallback(() => {
    const {
      location: { search },
    } = history

    const filtersFromUrl = qs.parse(search, { ignoreQueryPrefix: true }) as FiltersFromUrl
    const minKindValueFromUrl = toArray(filtersFromUrl[minKind])
    const maxKindValueFromUrl = toArray(filtersFromUrl[maxKind])

    const isMinKindValueAlreadyUsed = minKindValueFromUrl.includes(minKindValue)
    const isMaxKindValueAlreadyUsed = maxKindValueFromUrl.includes(maxKindValue)

    const isMulti = isFilterMultiRange(minKind) && isFilterMultiRange(maxKind)

    if (isMinKindValueAlreadyUsed && isMaxKindValueAlreadyUsed) {
      filtersFromUrl[minKind] = isMulti
        ? minKindValueFromUrl.filter(min => min !== minKindValue)
        : undefined
      filtersFromUrl[maxKind] = isMulti
        ? maxKindValueFromUrl.filter(max => max !== maxKindValue)
        : undefined
    } else {
      filtersFromUrl[minKind] = isMulti ? [...minKindValueFromUrl, minKindValue] : minKindValue
      filtersFromUrl[maxKind] = isMulti ? [...maxKindValueFromUrl, maxKindValue] : maxKindValue
    }

    return applyFilters(filtersFromUrl, history)
  }, [minKind, maxKind, history, maxKindValue, minKindValue])
}

export const useToggleOption = (filterOption: FilterOption, replace = false) => {
  const { kind, value } = filterOption
  return useToggleUrlFilter(kind, value, replace)
}

export const useClearFilter = () => {
  const history = useHistory()

  return useCallback(() => {
    const {
      location: { search },
    } = history

    const filtersFromUrl = qs.parse(search, { ignoreQueryPrefix: true }) as FiltersFromUrl
    const { q } = filtersFromUrl
    applyFilters({ q }, history)
  }, [history])
}

const getEditorialRange = (
  minValue: string,
  maxValue: string,
  range: FilterRange[]
): FilterRange | undefined => {
  const isEditorialRange = !range.some(
    range => minValue === range.min.toString() && maxValue === range.max.toString()
  )

  if (isEditorialRange) {
    return {
      min: parseFloat(minValue),
      max: parseFloat(maxValue),
      optionValues: [minValue, maxValue],
    }
  }

  return undefined
}

export const getDisableFilter = (kind: FilterKind, orderType: CustomerOrderType) => {
  const filtersForDisable: FilterKind[] = ['roxable']

  if (orderType === 'COMPLETE_PAIR') {
    return filtersForDisable.includes(kind)
  }

  return false
}

/**
 * Generate a filter range with selected and count option based
 * on the url params and the filter options
 *
 * @param defaultFilter The filter to generate a range for
 * @param enabledFilters The enabled filters from the url
 * @param filterOptions The filter options for this filter
 * @returns The filter range transformed with selected and count
 */
const generateFilterRange = (
  defaultFilter: DefaultFilter,
  enabledFilters: FiltersFromUrl,
  filterOptions: FilterOption[]
): FilterRange[] | undefined => {
  const { minKey, maxKey } = defaultFilter
  let { range } = defaultFilter

  if (isUndefined(range) || isUndefined(minKey) || isUndefined(maxKey)) {
    return
  }

  const minValueArray = toArray(enabledFilters[minKey])
  const maxValueArray = toArray(enabledFilters[maxKey])

  range = minValueArray.reduce<FilterRange[]>((newRange, minValue, index) => {
    const editorialRange = getEditorialRange(minValue, maxValueArray[index], newRange)

    if (editorialRange) {
      return [...newRange, editorialRange]
    }

    return newRange
  }, range)

  return range.map(currentRange => ({
    ...currentRange,
    selected:
      minValueArray.includes(currentRange.min.toString()) &&
      maxValueArray.includes(currentRange.max.toString()),
    count: currentRange.optionValues.reduce((totalCount, optionValue) => {
      const option = filterOptions?.find(o => o.value === optionValue)
      const optionCount = option ? option.count : 0
      return totalCount + optionCount
    }, 0),
  }))
}

/**
 * Transform the filters retrieved form the API response into a group following similar
 * interface for ease of usage across the application.
 *
 * @param filtersFromProducts The filter facets retrieved from the API response
 * @returns The filters transformed to be used by the application
 */
export const useFiltersState = (filtersFromProducts: FilterFacet[]) => {
  const filtersFromUrl = useFiltersFromUrl()
  const filtersFromRedux = useFiltersFromRedux()

  const customerOrderType = useSelector(s => s.customerOrder.orderType)

  const memoizedFilterState = useMemo(() => {
    const enabledFilters = {
      ...filtersFromUrl,
      ...filtersFromRedux,
    }

    const filterByKind = groupBy(filtersFromProducts, 'kind')
    const filterWithOptions = {} as FiltersState

    objectKeys(DEFAULT_FILTERS).forEach(filterKind => {
      const defaultFilter = (DEFAULT_FILTERS as DefaultFilters)[filterKind]

      const filters = filterByKind[filterKind]

      const newOptions = filters?.map(filter => ({
        ...filter,
        selected: isInArrayOrEqual(enabledFilters[filterKind], filter.value),
      }))

      filterWithOptions[filterKind] = {
        ...defaultFilter,
        options: newOptions,
        range: generateFilterRange(defaultFilter, enabledFilters, newOptions),
        disabled: getDisableFilter(filterKind, customerOrderType),
      }
    })

    return filterWithOptions
  }, [filtersFromUrl, filtersFromRedux, filtersFromProducts, customerOrderType])

  return memoizedFilterState
}

export const useApplyFilters = (filters: ActiveFilters[], preserveSearch = true) => {
  const history = useHistory()
  const location = useLocation()
  const { search } = location
  const parsedUrl = qs.parse(search, { ignoreQueryPrefix: true })
  const searchQuery = parsedUrl.query as string

  if (!filters) return undefined

  if (parsedUrl.query && preserveSearch) {
    filters.unshift({ kind: 'query', value: searchQuery })
  }

  const filterToApply: Record<string, string | string[]> = {}

  filters.forEach(f => {
    filterToApply[f.kind] = f.value
  })

  return () => {
    applyFilters(filterToApply, history)
  }
}

export const useSelectedFiltersCount = (filters: FiltersState) => {
  const selectedFiltersCount = useMemo(
    () =>
      Object.values(filters).reduce((selectedCount, filter) => {
        const selectedOptionsCount = filter.options?.filter(o => o.selected).length || 0
        const selectedRangeCount = filter.range?.filter(o => o.selected).length || 0
        return selectedCount + selectedOptionsCount + selectedRangeCount
      }, 0),
    [filters]
  )
  return selectedFiltersCount
}

export const usePredefinedFilters = (productType?: string) => {
  const location = useLocation()
  const customerOrderType = useSelector(s => s.customerOrder.orderType)

  const productTypeValue = getKindFilterValue(location, 'productType')
  const defaultProductType = productType || config.defaultProductType

  const toggleProductTypeFilter = useToggleUrlFilter(
    'productType',
    ProductTypeValues[defaultProductType as ProductTypeValues],
    true
  )

  if (!productTypeValue) {
    toggleProductTypeFilter()
  }

  const isPrescriptionAvailable = getKindFilterValue(location, 'roxable')
  const togglePrescriptionAvailable = useToggleUrlFilter('roxable', 'true')

  if (!isPrescriptionAvailable && customerOrderType === 'COMPLETE_PAIR') {
    togglePrescriptionAvailable()
  }
}

export const usePreselectedFilters = () => {
  const history = useHistory()
  const location = history.location
  // Preselected filters are preselected by the cms, from hero banner or menu option
  const [preselectedFilters, setPreselectedFilters] = useState<ActiveFilters[]>([])
  const hash = useRef<string>(uniqueId())
  const shouldUpdatePreselectedFilters = useSelector(s => s.ui.shouldUpdatePreselectedFilters)
  const actions = useActions({
    updatePreselectedFilters,
  })

  useEffect(() => {
    if (shouldUpdatePreselectedFilters) {
      hash.current = uniqueId()
    }
  }, [shouldUpdatePreselectedFilters])

  const activeFilters = getPLPFiltersFromLocation(location) as ActiveFilters[] | undefined

  useEffect(() => {
    const filteredActiveFilters = activeFilters?.filter(
      f => !['storeAvailable', 'roxable', 'productType'].includes(f.kind)
    )
    if (filteredActiveFilters) {
      executeOnce((filteredActiveFilters: ActiveFilters[]) => {
        setPreselectedFilters(filteredActiveFilters)
        actions.updatePreselectedFilters(false)
      }, hash.current)(filteredActiveFilters)
    }
  }, [location, activeFilters, actions])

  return { preselectedFilters }
}

/**
 * Map filters from the url query parameters to the param that needs to be sent to the
 * API through GraphQL. E.g. `maxPrice` and `minPrice` will be grouped in the `rangePrice`
 * param
 */
const filterKindToFormattedParamMap: Partial<Record<FiltersFromUrlKind, string>> = {
  minPrice: 'rangePrice',
  maxPrice: 'rangePrice',
}

const resolveFormattedFilterParam = (filterKind: FiltersFromUrlKind): string => {
  const filterParam = filterKindToFormattedParamMap[filterKind]

  if (isString(filterParam)) {
    return filterParam
  }

  return filterKind
}

const resolveFormattedFilterValue = <T extends FiltersFromUrlKind>(
  filterKind: T,
  filterValue: FiltersFromUrl[T],
  paramValue?: FormattedFiltersType
): FormattedFiltersType => {
  if (isFilterMultiRange(filterKind)) {
    return formatMultiRangeFilterValue(
      filterKind,
      filterValue,
      paramValue as FormattedFilterMultiRangeType[]
    )
  }

  return formatFilterValue(filterKind, filterValue)
}

/**
 * Format filters from the url to be transformed for the API request.
 * Url params might be parsed as string while the API is expecting numbers, etc
 * This hook will transform this values so that the correct params are send to the API.
 *
 * @param productType The product's type
 * @returns The formatted filters and the sort options that can be send to the API.
 */
export const useFormatFilters = () => {
  const filtersFromUrl = useFiltersFromUrl()
  const filtersFromRedux = useFiltersFromRedux()

  return useMemo(() => {
    const filters = {
      ...filtersFromUrl,
      ...filtersFromRedux,
    }

    const sort: Record<string, string> = {}

    if (filters.sort) {
      const [sortField, sortOrder] = (filters.sort as string).split(':')
      sort.sortField = sortField
      sort.sortOrder = sortOrder
    }
    delete filters.sort

    const formatedFilters = objectKeys(filters).reduce<FormattedFilters>(
      (newFilters, filterKind) => {
        const filterValue = filters[filterKind]

        if (filterValue) {
          const filterParam = resolveFormattedFilterParam(filterKind)
          newFilters[filterParam] = resolveFormattedFilterValue(
            filterKind,
            filterValue,
            newFilters[filterParam]
          )
        }

        return newFilters
      },
      {}
    )

    return { formatedFilters, sort }
  }, [filtersFromUrl, filtersFromRedux])
}

export const useFiltersDependency = () => {
  const filtersFromUrl = useFiltersFromUrl()
  const togglePrescriptionAvailable = useToggleUrlFilter('roxable', 'true')
  const toggleProgressiveFriendly = useToggleUrlFilter('progressiveFriendly', 'true')

  const isProgressiveFriendly = !!filtersFromUrl.progressiveFriendly
  const isPrescriptionAvailable = !!filtersFromUrl.roxable
  const prevRoxableFilterStatus = usePrevious(isPrescriptionAvailable)

  useEffect(() => {
    if (isProgressiveFriendly && !isPrescriptionAvailable && !prevRoxableFilterStatus) {
      togglePrescriptionAvailable()
    }
    if (isProgressiveFriendly && !isPrescriptionAvailable && prevRoxableFilterStatus) {
      toggleProgressiveFriendly()
    }
  }, [
    isProgressiveFriendly,
    isPrescriptionAvailable,
    prevRoxableFilterStatus,
    togglePrescriptionAvailable,
    toggleProgressiveFriendly,
  ])
}
