import { Dispatch } from 'redux'

export const RESET_STORE = 'store/RESET_STORE'

export interface ResetStoreAction {
  type: typeof RESET_STORE
}

export const resetStore = () => (dispatch: Dispatch<ResetStoreAction>) => {
  dispatch({
    type: RESET_STORE,
  })
}

export type StoreActionTypes = ResetStoreAction
