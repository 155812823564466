import React from 'react'
import i18next from 'i18next'
import { translate, WithNamespaces } from 'react-i18next'
import { DisclaimerWrapper } from './DisclaimerWrapper'

interface DisclaimerNPSProps extends WithNamespaces {
  className?: string
  t: i18next.TFunction
}

const DisclaimerNPSWithoutI18N: React.FC<DisclaimerNPSProps> = ({ t, className }) => (
  <DisclaimerWrapper className={className}>
    <div>{t('Core.disclaimerNPSrow1')}</div>
    <div>{t('Core.disclaimerNPSrow2')}</div>
  </DisclaimerWrapper>
)

export const DisclaimerNPS = translate('translation')(DisclaimerNPSWithoutI18N)
