import { combineReducers } from 'redux'
import { History } from 'history'
import { connectRouter, RouterActionType } from 'connected-react-router'
import { StoreActionTypes } from 'actions/store'
import cart from './cart'
import order from './order'
import customerOrder from './customerOrder'
import { engravingReducer as engraving } from '@abstract/react-engraving'
import { reducer as checkout } from '@abstract/react-checkout'
import keyboard from './keyboard'
import ui from './ui'
import { frameAdvisorReducer, FrameAdvisorActionTypes } from '@abstract/sss-frame-advisor'
import { sessionReducer } from '@abstract/core'
import { wishlistReducer, WishlistActionTypes } from '@abstract/sss-wishlist'

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    cart,
    checkout,
    order,
    wishlist: wishlistReducer,
    engraving,
    session: sessionReducer,
    keyboard,
    ui,
    frameAdvisor: frameAdvisorReducer,
    customerOrder,
  })

export default createRootReducer

export type StoreActionsTypes =
  | WishlistActionTypes
  | StoreActionTypes
  | RouterActionType
  | FrameAdvisorActionTypes
