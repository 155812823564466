import { RESET_STORE, StoreActionTypes } from '../actions/store'
import { AppReducerState } from 'store'

export default (state: AppReducerState, action: StoreActionTypes) => {
  switch (action.type) {
    case RESET_STORE: {
      return
    }
    default:
      return state
  }
}
