import { isMobileDevice } from '@abstract/core'
import config from 'config'
import { ProductTypeValues } from 'types/filter'
import { UndoData } from 'types/frameAdvisor'
import { StockItem } from 'types/cart'

/** Check whether the vKeyboard should be used or not
 * Vkeyboard is available only on desktop devices
 * and can be enable/disable by a configuration
 */
export const useVKeyboard = () => {
  if (isMobileDevice()) {
    return false
  }
  return config.useVKeyboard
}

/**
 * Returns a random string Id sufficiently unique
 * @returns {string} unique id
 */
export const uniqueId = () => Math.random().toString(36).substring(2, 15) + Date.now().toString(36)

// TODO: move to common lib
export const convertToCamelCaseAndStripSpecials = (str: string, firstUpperCase = false) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return '' // or if (/\s+/.test(match)) for white spaces
      return index === 0 && !firstUpperCase ? match.toLowerCase() : match.toUpperCase()
    })
    .replace(/[^0-9a-zA-Z]+/, '')
}

/**
 * Check is input value is UPC
 * @param upc
 * @returns {boolean}
 */
export const isUPC = (upc: string): boolean => {
  const match = upc.match(/^[0-9]{8,13}$/)
  return match ? true : false
}

/**
 * Returns opposite product type value (sun or optical)
 * @param productType
 * @returns {string}
 */
export const getOppositeProductType = (productType: string): string => {
  if (productType === ProductTypeValues.OPTICAL) return ProductTypeValues.SUN
  return ProductTypeValues.OPTICAL
}

/**
 * Returns an object with the data required for the UNDO feature on Frame advisor
 * @returns {UndoData} UndoData object
 */
export const formatProductForUndo = (product: UndoData) => ({
  moco: product.moco,
  name: product.name,
  model: product.model,
  UPC: product.UPC,
  brand: product.brand,
})

/**
 * Returns a string query with the product type value
 * @returns string value
 */

export const addProductTypeToQuery = (query: string, productType: string) => {
  return `${query}&productType=${productType}`
}

export const formatProductCode = (code: string): string => {
  const ooProductCode = code.replace(/^(W|w)[G0]/gi, '')
  return ooProductCode.replace(/^0/, '')
}

export class LocaleCode {
  static readonly REG = /^([a-z]{2})-([A-Z]{2})$/

  /* language iso-639-1 */
  static getLanguageCode(code: string): string {
    const match = code.match(this.REG)
    if (!match || match.length < 1) return ''
    return match[1]
  }
}

export const createMouseEvents = (mouseEventTitles: string[]) =>
  mouseEventTitles.map(
    eventTitle =>
      new MouseEvent(eventTitle, {
        view: window,
        bubbles: true,
        cancelable: true,
      })
  )

export const simulateEvents = (el: Element, events: Event[]) => {
  events.forEach(event => el.dispatchEvent(event))
}

export const isCurrentProductInStock = (
  stockItems: StockItem[],
  upc: string,
  shouldAllVariantsBeAvailable = false
) => {
  const filteredProducts = stockItems.filter(item => item.upc === upc)
  if (shouldAllVariantsBeAvailable) {
    return Boolean(filteredProducts.length && filteredProducts.every(item => !!item.qty))
  }
  return filteredProducts.some(item => !!item.qty)
}
