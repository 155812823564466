import { isIPadView } from '@abstract/core'
import { isIosVersionFrom } from '@abstract/virtual-mirror'
import { useStoreData } from 'data'
import { useMemo } from 'react'
import config from 'config'

export const useVMVisibility = () => {
  const { data: storeData } = useStoreData()

  const store = storeData?.store

  return useMemo(() => {
    const isIpad = isIPadView()
    const vmEnabled = config.vmEnable && !!store?.vtoEnabled
    const ipadEnabled =
      isIpad && !!store?.vtoIPadEnabled && isIosVersionFrom('11.4.0') && config.vmIPadEnable
    const monitorEnabled = !isIpad && !!store?.vtoMonitorEnabled

    return {
      isVMEnabled: vmEnabled && (ipadEnabled || monitorEnabled),
      isVMIpadEnabled: vmEnabled && ipadEnabled,
      isVMMonitorEnabled: vmEnabled && monitorEnabled,
    }
  }, [store])
}
