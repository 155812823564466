import React, { useEffect } from 'react'
import styled from 'styled-components'
import config from 'config'
import { theme } from 'theme'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setDiscountBannerVisibility } from 'actions/ui'
import { useDiscountBanner } from 'data'

const BannerWrapper = styled.div<{ bgColor: string }>`
  position: relative;
  margin-bottom: 1.864rem;
  padding: 0.864rem;
  width: 100%;
  font-size: 0.909rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  text-transform: uppercase;
  background-color: ${({ bgColor }) => bgColor};
`

interface DiscountBannerProps {
  className?: string
}

const ContentBanner: React.FC<DiscountBannerProps> = ({ className }) => {
  const { data } = useDiscountBanner()
  return (
    <>
      {data && data.discountBanner && (
        <BannerWrapper
          className={className}
          bgColor={data?.discountBanner.backgroundColor || theme.colors.monza}
        >
          {data?.discountBanner.teaserTitle}
        </BannerWrapper>
      )}
    </>
  )
}

const availableOn = ['wishlist', 'cart', 'products']

export const DiscountBanner: React.FC<DiscountBannerProps> = props => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { discountBannerIsVisible, searchDrawerIsVisible } = useSelector(s => s.ui)

  useEffect(() => {
    dispatch(
      setDiscountBannerVisibility(
        config.showDiscount &&
          (searchDrawerIsVisible || availableOn.some(x => location.pathname.endsWith(x)))
      )
    )
  }, [dispatch, location.pathname, searchDrawerIsVisible])

  return <>{discountBannerIsVisible && <ContentBanner {...props} />}</>
}
