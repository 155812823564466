import { useSelector } from 'react-redux'
import { useActions } from '@abstract/core'
import { toggleItemToWishlist } from '@abstract/sss-wishlist'

export const useWishList = (UPC: string) => {
  const wishlisted = useSelector(state => {
    return state.wishlist.items.some(item => item.UPC === UPC)
  })

  const actions = useActions({
    toggleItemToWishlist,
  })

  return { ...actions, wishlisted }
}
