
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"EngravingData"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Product"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"engravingConstraints"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"price"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"fonts"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"colors"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"sides"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"maxChars"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"oneEng"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"globSides"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"engGlob"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":158}};
    doc.loc.source = {"body":"fragment EngravingData on Product {\n  engravingConstraints {\n    price\n    fonts\n    colors\n    sides\n    maxChars\n    oneEng\n    globSides\n    engGlob\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
