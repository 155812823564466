import React from 'react'
import { Service } from '@abstract/react-environment-info'
import config from '../config'
import styled from 'styled-components'
import { DEFAULT_STORETYPE } from 'hooks/useStoreIdentity'

const ServiceContainer = styled.div`
  overflow-x: scroll;
  height: 100vh;
`
const ServicePage = () => {
  return (
    <ServiceContainer>
      <Service config={config} logServerUrl={config.logServerUrl} storeType={DEFAULT_STORETYPE} />
    </ServiceContainer>
  )
}

export default ServicePage
