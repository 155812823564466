/* eslint-disable @typescript-eslint/camelcase */
import config from 'config'
import { hideAllDrawer as hideAllDrawerAction, updatePreselectedFilters } from 'actions/ui'
import { NoDataContent } from 'components/Contents/NoDataContent'
import { RBButton } from 'components/Button'
import ErrorWidget from 'components/ErrorWidget'
import Loading from 'components/Loading'
import { ProductList } from 'components/ProductsList'
import { useSearchData } from 'data'
import { useAction, usePrevious, usePreviousNonNullish } from '@abstract/core'
import { useStoreIndentity } from 'hooks/useStoreIdentity'
import { useUrlParams } from 'hooks/useUrlParams'
import { parseGrapqhQLError } from 'libs/errors'
import { isUPC, addProductTypeToQuery } from 'libs/utils'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { translate, WithNamespaces } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { useSendAnalyticsEvent } from 'containers/AnalyticsProvider'
import { useFormatSearchReqProductData } from 'hooks/useFormatReqProductData'
import { Store } from 'types/store'
import {
  SwitcherWithLabel,
  SwitcherWithLableStyled,
  Label,
  SwitcherValue,
} from '../MainMenu/MenuSwitcher'
import { useAvailableInStore } from 'hooks/useAvailableInStore'
import { Product } from 'types/product'
import { CustomerOrderType } from 'types/common'
import { useFiltersState } from 'hooks/useFilters'
import { ProductTypeTabs } from './ProductTypeTabs'
import { ProductTypeValues } from 'types/filter'

const NUMBER_OF_PREVIEW_ITEMS = 4

interface SearchProductsProps {
  query: string
  store: Store
}

export const isAvailableInStore = (availableInStore: boolean) => (product: Product) =>
  availableInStore ? Number(product.availability?.stock) > 0 : true

const NotFoundMessage = styled.p`
  margin: 1rem 0 0;
  padding: 1rem;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.emperor};
`

const ViewAllWrapper = styled.div`
  display: flex;
  justify-content: center;

  ${RBButton} {
    margin: 2.45rem;
    min-width: 8.2rem;
  }
`

const SettingsWrapper = styled.div<{ noData?: boolean }>`
  margin-top: 1rem;
  display: flex;
  justify-content: ${({ noData }) => (noData ? 'flex-end' : 'space-between')};

  ${SwitcherWithLableStyled} {
    margin: 0;
    padding: 1rem;
    justify-content: flex-end;
    width: 40%;
    font-size: 1rem;
  }

  ${Label} {
    margin-left: 1rem;
  }

  ${SwitcherValue} {
    font-size: 0.72rem;
  }
`

interface SearchProductsContentProps extends WithNamespaces {
  items: Product[]
  query: string
  store: Store
  orderType: CustomerOrderType
  fromBarcode: boolean
  fromCustomerOrder: boolean
  productPageUrl: string
  filtersProductType: ProductTypeValues[]
  selectedProductType: ProductTypeValues | ''
  setSelectedProductType: (value: ProductTypeValues) => void
}

const SearchProductsContentWithoutI18n: React.FC<SearchProductsContentProps> = ({
  items,
  query,
  store,
  t,
  orderType,
  fromBarcode,
  fromCustomerOrder,
  productPageUrl,
  selectedProductType,
  filtersProductType,
  setSelectedProductType,
}) => {
  const noResultsFiltersProductType = [ProductTypeValues.SUN].concat(
    store.eyeglassesEnabled ? [ProductTypeValues.OPTICAL] : []
  )

  const initialNoResultsProductType =
    selectedProductType && noResultsFiltersProductType.includes(selectedProductType)
      ? selectedProductType
      : (config.defaultProductType as ProductTypeValues)

  const [selectedNoResultProductType, setSelectedNoResultProductType] = useState<ProductTypeValues>(
    initialNoResultsProductType
  )
  const history = useHistory()
  const hideAllDrawer = useAction(hideAllDrawerAction)
  const dispatch = useDispatch()
  const { availableInStore, toggleAvailableInStore } = useAvailableInStore(selectedProductType)
  const {
    availableInStore: availableInStoreNoResult,
    toggleAvailableInStore: toggleAvailableInStoreNoResult,
  } = useAvailableInStore(selectedNoResultProductType)

  const nItems = items.length

  const filteredByAvailableInStoreItems = items.filter(isAvailableInStore(availableInStore))
  const filteredAndSlicedItems = filteredByAvailableInStoreItems.slice(0, NUMBER_OF_PREVIEW_ITEMS)
  const productCount = !isUPC(query) ? filteredByAvailableInStoreItems.length ?? 0 : nItems

  const isOrderTypeMatch = orderType === 'COMPLETE_PAIR' ? items[0]?.roxable : true
  const isRedirectFromCustomerOrder = fromCustomerOrder && nItems === 1 && isOrderTypeMatch
  const isNoDataContentShown =
    nItems === 0 || filteredByAvailableInStoreItems.length === 0 || !isOrderTypeMatch

  const handleChangeProductType = (value: ProductTypeValues) => {
    if (noResultsFiltersProductType.includes(value)) {
      setSelectedNoResultProductType(value)
    }

    setSelectedProductType(value)
  }

  const renderProductTypeTabs = () => {
    if (!selectedProductType && !selectedNoResultProductType) return null

    if (isNoDataContentShown && selectedNoResultProductType && noResultsFiltersProductType.length) {
      return (
        <ProductTypeTabs
          filtersProductType={noResultsFiltersProductType}
          selectedProductType={selectedNoResultProductType}
          onChange={handleChangeProductType}
        />
      )
    }

    if (!isNoDataContentShown && selectedProductType && filtersProductType.length) {
      return (
        <ProductTypeTabs
          filtersProductType={filtersProductType}
          selectedProductType={selectedProductType}
          onChange={handleChangeProductType}
        />
      )
    }
  }

  if (isRedirectFromCustomerOrder) {
    return <Redirect to={items[0].url} />
  }

  return (
    <>
      {renderProductTypeTabs()}
      {isNoDataContentShown ? (
        <>
          <SettingsWrapper noData>
            <SwitcherWithLabel
              checked={availableInStoreNoResult}
              onChange={toggleAvailableInStoreNoResult}
              label={t('PlpFilters.storeAvailable')}
            />
          </SettingsWrapper>
          <NoDataContent
            store={store}
            fromBarcode={fromBarcode}
            productTypeFilterValue={selectedNoResultProductType}
          />
        </>
      ) : (
        <>
          {selectedProductType && (
            <>
              <SettingsWrapper>
                <NotFoundMessage>
                  {t('SearchResults.productCounter', { count: productCount })}
                </NotFoundMessage>
                <SwitcherWithLabel
                  checked={availableInStore}
                  onChange={toggleAvailableInStore}
                  label={t('PlpFilters.storeAvailable')}
                />
              </SettingsWrapper>
              <ProductList
                items={isUPC(query) ? items : filteredAndSlicedItems}
                store={store}
                isSearchList={true}
              />
              <ViewAllWrapper>
                <RBButton
                  data-element-id="Tiles_ViewAll"
                  variant="outlined"
                  onClick={() => {
                    history.push(
                      `${productPageUrl}?query=${encodeURI(
                        addProductTypeToQuery(query, selectedProductType)
                      )}`
                    )
                    dispatch(updatePreselectedFilters(true))
                    hideAllDrawer()
                  }}
                >
                  {t('ButtonSearch.viewAll')}
                </RBButton>
              </ViewAllWrapper>
            </>
          )}
        </>
      )}
    </>
  )
}

const SearchProductsContent = translate('translation')(SearchProductsContentWithoutI18n)

export const SearchProducts: React.FC<SearchProductsProps> = ({ query, store }) => {
  const [selectedProductType, setSelectedProductType] = useState<ProductTypeValues | ''>('')
  const prevQuery = usePrevious(query)

  const { basePath } = useStoreIndentity()
  const { fromBarcode, fromCustomerOrder } = useUrlParams()
  const { orderType, salesOrderId } = useSelector(s => s.customerOrder)
  const productPageUrl = `${basePath}/products`

  const { data: searchReqData } = useFormatSearchReqProductData(store, query, selectedProductType)

  const { data: searchData, loading: searchLoading, error: searchError } = useSearchData(
    productPageUrl,
    searchReqData
  )
  const prevData = usePreviousNonNullish(searchData)
  const existingSearchData = !isEmpty(searchData) && searchData ? searchData : prevData

  const filtersFromProducts = existingSearchData?.products.filters ?? []
  const filters = useFiltersState(filtersFromProducts)
  const filtersProductType =
    filters.productType?.options?.map(option => option.value as ProductTypeValues) ?? []

  const history = useHistory()

  const analyticsData: { [key: string]: string } = {
    id: 'SearchPageReady',
    Search_Keyword: query,
    Search_Type: fromBarcode ? 'barcode' : 'text',
    Search_View: 'SERB',
  }

  if (!fromBarcode) {
    analyticsData['Search_ResultItemsQnt'] = (existingSearchData?.products.numRows ?? 0).toString()
  }

  if (fromCustomerOrder) {
    analyticsData[
      'Tags_AdobeAnalytics_TrafficSourceCid_ThisHit'
    ] = `LX-XCO_${orderType}_${salesOrderId}`
  }

  const sendAnalyticsEvent = useSendAnalyticsEvent(analyticsData)

  useEffect(() => {
    // in case emptying search query manually remove &fromBarcode=true from the url
    return () => {
      fromBarcode && history.replace(history.location.pathname)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!selectedProductType && !searchLoading) {
      setSelectedProductType((filtersProductType[0] as ProductTypeValues) || '')
    }
  }, [selectedProductType, filtersProductType, searchLoading])

  useEffect(() => {
    if (prevQuery !== query) {
      // in case changing search query manually remove &fromBarcode=true from the url
      fromBarcode && history.replace(history.location.pathname)
      setSelectedProductType('')
    }
  }, [query, prevQuery, history, fromBarcode])

  useEffect(() => {
    if (
      searchData &&
      !searchLoading &&
      !searchError &&
      !fromBarcode &&
      !(selectedProductType in ProductTypeValues)
    )
      sendAnalyticsEvent()
  }, [searchData, searchError, searchLoading, sendAnalyticsEvent, fromBarcode, selectedProductType])

  if (!existingSearchData || searchLoading) {
    return <Loading />
  }

  if (searchError) {
    return <ErrorWidget {...parseGrapqhQLError(searchError)} withWrapper />
  }

  return (
    <SearchProductsContent
      items={existingSearchData.products.items}
      store={store}
      query={query}
      orderType={orderType}
      fromBarcode={fromBarcode}
      fromCustomerOrder={fromCustomerOrder}
      productPageUrl={productPageUrl}
      filtersProductType={filtersProductType}
      setSelectedProductType={setSelectedProductType}
      selectedProductType={selectedProductType}
    />
  )
}
