import { CartItemType, factoryCartReducer } from '@abstract/react-checkout'
import { isCustomProduct } from 'libs/cart'
import { getClient } from '../apolloClient'
import ADDTOCART_QUERY from '../graphql/mutations/addtocart.graphql'
import Log from '../libs/Log'

const apolloClient = getClient()

const notifyAddToCart = (payload: CartItemType) => {
  if (isCustomProduct(payload)) return

  apolloClient
    .mutate({
      mutation: ADDTOCART_QUERY,
      // eslint-disable-next-line @typescript-eslint/camelcase
      variables: {
        availability: {
          storeId: payload.storeId,
          upcs: [
            {
              upc: payload.product.UPC,
              prescriptionApplied: payload.product.completePair,
            },
          ],
        },
      },
      context: {
        headers: {
          'dw-session-id': payload.session,
        },
      },
    })
    .then(res => {
      Log.trace(res.toString())
    })
    .catch(err => {
      Log.error(err)
    })
}

const cartReducer = factoryCartReducer(notifyAddToCart)

export default cartReducer
