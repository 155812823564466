import { useCallback } from 'react'
import { useActions } from '@abstract/core'
import { resetStore } from 'actions/store'
import { useStoreData } from 'data'
import { useHistory } from 'react-router-dom'
import { resetLocalStorageState } from 'localStorage'
import { cleanupOneConfiguratorRecipes } from '@abstract/one-configurator'
import { useStoreIndentity } from './useStoreIdentity'

export const useResetSS = () => {
  const actions = useActions({
    resetStore,
  })
  const { data: storeData } = useStoreData()
  const { storeType, storeId, langCode } = useStoreIndentity()
  const history = useHistory()

  const defaultLang = storeData?.store?.defaultLangCode || langCode
  const basePath = `/${storeType}/${storeId}/${defaultLang}`

  const resetSS = useCallback(() => {
    actions.resetStore()
    cleanupOneConfiguratorRecipes()
    resetLocalStorageState()
    history.push(basePath)
    location.reload(true) // force reload without cache to load new assets
  }, [actions, basePath, history])

  return resetSS
}
