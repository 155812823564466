import React, { useEffect } from 'react'
import { Trans } from 'react-i18next'
import { Modal } from '@abstract/sss-elements'
import styled from 'styled-components'
import { usePrivacyPolicy } from 'data'

const ModalHeader = styled.div`
  margin-bottom: 3em;
`

const PrivacyPolicyContent = styled.div``

interface PrivacyPolicyModalProps {
  className?: string
  visible: boolean
  onCancel: (event: React.MouseEvent<Element, MouseEvent>) => void
}

export const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = ({
  className,
  visible,
  onCancel,
}) => {
  const [loadData, { data }] = usePrivacyPolicy()

  useEffect(() => {
    if (visible) {
      loadData()
    }
  }, [loadData, visible])

  return (
    <Modal
      className="privacy-policy"
      width="75%"
      visible={visible}
      onCancel={onCancel}
      renderHeader={() => (
        <ModalHeader>
          <h1 className="link-to-modal__modal-header">
            <Trans i18nKey="Checkout.modalHeaderPolicy" />
          </h1>
        </ModalHeader>
      )}
    >
      <PrivacyPolicyContent
        className={className}
        dangerouslySetInnerHTML={{ __html: (data && data.privacyPolicy.policy) || '' }}
      />
    </Modal>
  )
}
