import React from 'react'
import { useStoreIndentity } from 'hooks/useStoreIdentity'
import { Link } from 'react-router-dom'
import { HeaderContainer } from './styles'
import { Logo } from 'components/Logo'
import { LogoColor } from '../../types/common'

const Header: React.FC<{ logoColor?: LogoColor; className?: string }> = ({
  logoColor,
  className,
  children,
}) => {
  const { basePath } = useStoreIndentity()
  return (
    <HeaderContainer className={className}>
      {children || (
        <Link to={`${basePath}`} data-element-id="MainNav_Logo">
          <Logo logoColor={logoColor} />
        </Link>
      )}
    </HeaderContainer>
  )
}

export default Header
