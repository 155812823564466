import styled from 'styled-components'

export const HeaderContainer = styled.div`
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 2.636rem;
  display: flex;
  width: 100vw;

  a {
    width: 12.773rem;
    height: 100%;
    @media only screen and (max-device-width: 1024px) {
      width: 6.68rem;
    }
  }

  img {
    display: block;
    @media only screen and (max-device-width: 1024px) {
      width: 100%;
    }
  }
`
