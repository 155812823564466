import { useMemo } from 'react'
import { Store } from 'types/store'
import { useFormatFilters } from './useFilters'
import config from 'config'
import { FiltersReq } from 'types/filter'
import { getLangFromUrl } from '@abstract/core'
import { useCustomerOrder } from './useCustomOrder'

export const useFormatReqProductData = (
  store: Store,
  incomingFilters?: FiltersReq,
  itemPerPage = config.itemsPerPage
) => {
  const { storeId, storeType, langCode } = store
  const { formatedFilters, sort } = useFormatFilters()
  const { isCompletePairOrderType } = useCustomerOrder()
  const filters = incomingFilters ? incomingFilters : formatedFilters

  return useMemo(() => {
    const data = {
      filters: {
        ...(isCompletePairOrderType && { roxable: true }),
        ...filters,
        store: storeId,
        lang: getLangFromUrl().split('-')[0],
      },
      ...sort,
      rows: itemPerPage,
      start: 0,
    }
    return {
      storeId,
      storeType,
      langCode,
      data,
    }
  }, [langCode, storeId, storeType, filters, itemPerPage, sort, isCompletePairOrderType])
}

export const useFormatSearchReqProductData = (
  store: Store,
  query: string,
  productType?: string | null
) => {
  const { data: reqData } = useFormatReqProductData(store)
  return useMemo(() => {
    const data = {
      ...reqData,
      filters: {
        ...(reqData.filters.roxable && { roxable: true }),
        store: reqData.filters.store,
        lang: reqData.filters.lang,
        query,
        ...(productType && { productType: [productType] }),
      },
      rows: reqData.rows,
      start: reqData.start,
    }

    return {
      data,
    }
  }, [query, reqData, productType])
}
