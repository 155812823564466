import styled from 'styled-components'

export const DisclaimerWrapper = styled.div`
  z-index: 1;
  position: fixed;
  right: 0;
  bottom: 1rem;
  left: 0;
  padding: 0 4.5rem;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 0.545rem;
  color: ${({ theme }) => theme.colors.gray};
  line-height: 1.5;
  text-align: center;

  a {
    color: ${({ theme }) => theme.colors.gray};
    text-decoration: underline;
  }
`
