import { Dispatch } from 'react'

export const SET_ORDER = 'order/SET_ORDER'

export interface SetOrderAction {
  type: typeof SET_ORDER
  payload: {
    orderNumber: string
    posReceiptId: string
  }
}

export const setOrderNumber = (orderNumber: string, posReceiptId: string) => (
  dispatch: Dispatch<SetOrderAction>
) => {
  dispatch({
    type: SET_ORDER,
    payload: {
      orderNumber,
      posReceiptId,
    },
  })
}

export type OrderActionTypes = SetOrderAction
