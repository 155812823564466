import styled from 'styled-components'
import { device } from 'styles/variables'

export const BULLET_SIZE = 16

export const ProductsSlidesContainer = styled.div`
  padding-top: 2rem;
  width: 50%;

  @media ${device.ipadMiniPortraitRealframeAdvisor} {
    width: 80%;
  }

  @media only ${device.ipadPortrait} {
    padding-top: 1rem;
    width: 60%;
  }
`

export const Image = styled.img``

export const SlideTitle = styled.div`
  margin-bottom: 1rem;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 2.27rem;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`

const slideWidthPer = 80

export const SliderContainer = styled.div`
  width: 100%;
  overflow: hidden;

  .swiper-slide {
    width: ${slideWidthPer}%;
  }

  .swiper-container {
    position: relative;
    left: ${(100 - slideWidthPer * 3) / 2}%;
    padding-bottom: 2.3rem;
    width: ${slideWidthPer * 3}%;

    &-first .swiper-pagination-bullet,
    &-second .swiper-pagination-bullet {
      left: 0 !important; /* must override swiper js */
    }

    &-penultimate .swiper-pagination.swiper-pagination-bullets {
      padding-left: ${BULLET_SIZE}px; /* simulate fixed left with growing padding */
    }

    &-last .swiper-pagination.swiper-pagination-bullets {
      padding-left: ${BULLET_SIZE * 2}px; /* simulate fixed left with growing padding */
    }
  }

  .swiper-pagination.swiper-pagination-bullets {
    &.swiper-pagination-bullets-dynamic {
      transform: translate3d(-50%, 0, 0) scale(3.33);
    }

    .swiper-pagination-bullet {
      border: 1px solid ${({ theme }) => theme.colors.white};
      opacity: 1;
      background-color: transparent;
    }

    .swiper-pagination-bullet-active {
      background-color: rgba(255, 255, 255, 0.48);

      &-main {
        transform: scale(0.6);
      }

      &-next,
      &-prev {
        transform: scale(0.45);
      }

      &-next-next,
      &-prev-prev {
        transform: scale(0.3);
      }
    }
  }
`
