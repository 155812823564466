/* eslint-disable @typescript-eslint/camelcase */
import React from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { useStoreIndentity } from 'hooks/useStoreIdentity'
import { useDispatch } from 'react-redux'
import { togglePDPCustomizeModal } from 'actions/ui'
import { getSearchValueFromQs } from '@abstract/core'
import { useSearchData, useStoreData } from 'data'
import { useFormatSearchReqProductData } from 'hooks/useFormatReqProductData'
import Loading from 'components/Loading'
import { Store } from 'types/store'

interface BarcodeActionsProps {
  store: Store
}

export const BarcodeActionsWrapper = () => {
  const { data: storeData } = useStoreData()
  return storeData ? <BarcodeActions store={storeData.store} /> : <></>
}

const BarcodeActions: React.FC<BarcodeActionsProps> = ({ store }) => {
  const history = useHistory()
  const initialQuery = getSearchValueFromQs(history.location)
  const { basePath } = useStoreIndentity()
  const dispatch = useDispatch()

  const productPageUrl = `${basePath}/products`

  const { data: searchReqData } = useFormatSearchReqProductData(store, initialQuery)

  const { data: searchData, loading: searchLoading } = useSearchData(productPageUrl, searchReqData)

  const searchItems = searchData?.products.items || []
  const nItems = searchItems.length

  if (!searchData || searchLoading) {
    return <Loading />
  }

  if (nItems === 1) {
    // This is used to tell the PDP if the modal asking for product customization should be shown or not
    dispatch(togglePDPCustomizeModal)
    return <Redirect to={`${searchItems[0].url}?comesFromBarcode=true`} />
  }
  return <Redirect to={`${basePath}?q=${initialQuery}&fromBarcode=true`} />
}
