import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useStoreIndentity } from 'hooks/useStoreIdentity'
import { useSelector, useDispatch } from 'react-redux'
import { updateVisited } from 'actions/frameAdvisor'

const FrameAdvisorActions = () => {
  const { isFrameAdvisorVisited } = useSelector(s => s.frameAdvisor)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isFrameAdvisorVisited) {
      dispatch(updateVisited(true))
    }
  }, [dispatch, isFrameAdvisorVisited])

  return <></>
}

export const FrameAdvisorRouteActions = () => {
  const { basePath } = useStoreIndentity()
  const videoId = useSelector(state => state.frameAdvisor.fsaVideoId)
  return (
    <>
      <Route path={`${basePath}/frame-advisor`}>
        <FrameAdvisorActions />
      </Route>
      <Route path={`${basePath}/frame-advisor/(connectfsa|fsa)/:id*`}>
        {videoId && <Redirect to={`${basePath}/frame-advisor/connected`} />}
      </Route>
      <Route path={`${basePath}/frame-advisor/logoutfsa`}>
        {!videoId && <Redirect to={`${basePath}`} />}
      </Route>
      <Route path={`${basePath}/frame-advisor/survey/:id*`}>
        {videoId && <Redirect to={`${basePath}/frame-advisor/logoutfsa`} />}
      </Route>
    </>
  )
}
