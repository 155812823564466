import {
  FILTER_TYPE_BRAND,
  FILTER_TYPE_CHECKBOX,
  FILTER_TYPE_FLAG,
  FILTER_TYPE_PRICE,
  FILTER_TYPE_RANGE,
} from '@abstract/ss-filters'
import { FilterType } from '@abstract/ss-filters/dist/types/types/filters'

export interface FilterInput {
  kind: FilterKind
  value: string
}

export interface FilterFacet extends FilterInput {
  count: number
}

export type FilterOption = FilterFacet & {
  selected: boolean
  translatedLabel?: string
}

export type FilterState = DefaultFilter & {
  options: FilterOption[]
  translatedLabel?: string
  disabled?: boolean
}

export type FiltersState = {
  [kind in FilterKind]: FilterState
}

export type AppFiltersKind = 'q' | 'sort'

export type RangeFiltersKind = 'minLensHeight' | 'maxLensHeight'

export type MultiRangeFiltersKind = 'minPrice' | 'maxPrice'

export type FiltersFromUrlType = string | string[]

export type FiltersFromUrlKind =
  | FilterKind
  | AppFiltersKind
  | RangeFiltersKind
  | MultiRangeFiltersKind

export type FiltersFromUrl = {
  [kind in FiltersFromUrlKind]?: FiltersFromUrlType
}

// Formatted filters that are applied on url parameters
export type FormattedFilterMultiRangeType = Record<MultiRangeFiltersKind, number>

export type FormattedFiltersType =
  | string
  | string[]
  | number
  | number[]
  | boolean
  | FormattedFilterMultiRangeType[]
  | undefined

export type FormattedFilters = Record<string, FormattedFiltersType>

export enum ProductTypeValues {
  OPTICAL = 'OPTICAL',
  SUN = 'SUN',
}

export type FiltersReq = Record<string, string[] | boolean | number>

export const PRICE_MIN_VALUE = 0

export const PRICE_MAX_VALUE = 600

/**
 * Default filters state. Every filter has following fields:
 * 1) key - key of corresponding category received from API ('kind' field)
 * 2) label - translation key to display in title
 * 3) type - type of filter (checkbox, color, range)
 * 4) options - array of available options.
 *
 * Each filter option has following fields:
 * 1) active - if option is selected
 * 2) count - number of products corresponding the option (received from API)
 * 3) label - translation label
 * 4) value - value of the option received from API ('value' field)
 */

export const DEFAULT_FILTERS = {
  brand: {
    key: 'brand',
    label: 'brand',
    type: FILTER_TYPE_BRAND,
  },
  category: {
    key: 'category',
    label: 'category',
    type: FILTER_TYPE_CHECKBOX,
  },
  storeAvailable: {
    key: 'storeAvailable',
    label: 'storeAvailable',
    type: FILTER_TYPE_FLAG,
  },
  engravable: {
    key: 'engravable',
    label: 'engravable',
    type: FILTER_TYPE_FLAG,
  },
  gender: {
    key: 'gender',
    label: 'gender',
    type: FILTER_TYPE_CHECKBOX,
  },
  faceShape: {
    key: 'faceShape',
    label: 'faceShape',
    type: FILTER_TYPE_CHECKBOX,
  },
  fit: {
    key: 'fit',
    label: 'fit',
    type: FILTER_TYPE_CHECKBOX,
  },
  frameColor: {
    key: 'frameColor',
    label: 'frameColor',
    type: FILTER_TYPE_CHECKBOX,
  },
  frameType: {
    key: 'frameType',
    label: 'frameType',
    type: FILTER_TYPE_CHECKBOX,
  },
  frameShape: {
    key: 'frameShape',
    label: 'frameShape',
    type: FILTER_TYPE_CHECKBOX,
  },
  frameMaterial: {
    key: 'frameMaterial',
    label: 'frameMaterial',
    type: FILTER_TYPE_CHECKBOX,
  },
  lensColor: {
    key: 'lensColor',
    label: 'lensColor',
    type: FILTER_TYPE_CHECKBOX,
  },
  lensMaterial: {
    key: 'lensMaterial',
    label: 'lensMaterial',
    type: FILTER_TYPE_CHECKBOX,
  },
  lensTreatment: {
    key: 'lensTreatment',
    label: 'lensTreatment',
    type: FILTER_TYPE_CHECKBOX,
  },
  polarizedLens: {
    key: 'polarizedLens',
    label: 'polarizedLens',
    type: FILTER_TYPE_FLAG,
  },
  vto: {
    key: 'vto',
    label: 'vto',
    type: FILTER_TYPE_FLAG,
  },
  roxable: {
    key: 'roxable',
    label: 'roxable',
    type: FILTER_TYPE_FLAG,
  },
  progressiveFriendly: {
    key: 'progressiveFriendly',
    label: 'progressiveFriendly',
    type: FILTER_TYPE_FLAG,
  },
  lensHeight: {
    key: 'lensHeight',
    label: 'lensHeight',
    type: FILTER_TYPE_RANGE,
    minKey: 'minLensHeight',
    maxKey: 'maxLensHeight',
    range: [
      {
        min: 22,
        max: 57,
        optionValues: ['22_57'],
      },
    ],
  },
  price: {
    key: 'price',
    label: 'price',
    type: FILTER_TYPE_PRICE,
    minKey: 'minPrice',
    maxKey: 'maxPrice',
    range: [
      {
        min: 51,
        max: 150,
        optionValues: ['51_100', '101_150'],
      },
      {
        min: 151,
        max: 250,
        optionValues: ['151_200', '201_250'],
      },
      {
        min: 251,
        max: 400,
        optionValues: ['251_300', '301_350', '351_400'],
      },
      {
        min: 401,
        max: 600,
        optionValues: ['401_450', '451_500', '501_550', '551_600'],
      },
    ],
  },
  productType: {
    key: 'productType',
    label: 'productType',
    type: FILTER_TYPE_CHECKBOX,
  },
  customizable: {
    key: 'customizable',
    label: 'customizable',
    type: FILTER_TYPE_CHECKBOX,
  },
  sizeOrdinal: {
    key: 'sizeOrdinal',
    label: 'size',
    type: FILTER_TYPE_CHECKBOX,
  },
}

export type FilterKind = keyof typeof DEFAULT_FILTERS

export type FilterRange = {
  min: number
  max: number
  optionValues: readonly string[]
  selected?: boolean
  count?: number
}

export type DefaultFilter = {
  key: string
  label: string
  type: FilterType
  minKey?: RangeFiltersKind
  maxKey?: RangeFiltersKind
  range?: FilterRange[]
}

export type DefaultFilters = {
  [kind in FilterKind]: DefaultFilter
}
/**
 * Default filters state. Every filter has following fields:
 * 1) key - key of corresponding category received from API ('kind' field)
 * 2) label - translation key to display in title
 * 3) type - type of filter (checkbox, color, range)
 * 4) options - array of available options.
 *
 * Each filter option has following fields:
 * 1) active - if option is selected
 * 2) count - number of products corresponding the option (received from API)
 * 3) label - translation label
 * 4) value - value of the option received from API ('value' field)
 */

export const DEFAULT_FA_FILTERS = {
  brand: {
    key: 'brand',
    label: 'brand',
    type: FILTER_TYPE_BRAND,
  },
  category: {
    key: 'category',
    label: 'category',
    type: FILTER_TYPE_CHECKBOX,
  },
  storeAvailable: {
    key: 'storeAvailable',
    label: 'storeAvailable',
    type: FILTER_TYPE_FLAG,
  },
  engravable: {
    key: 'engravable',
    label: 'engravable',
    type: FILTER_TYPE_FLAG,
  },
  fit: {
    key: 'fit',
    label: 'fit',
    type: FILTER_TYPE_CHECKBOX,
  },
  frameColor: {
    key: 'frameColor',
    label: 'frameColor',
    type: FILTER_TYPE_CHECKBOX,
  },
  frameType: {
    key: 'frameType',
    label: 'frameType',
    type: FILTER_TYPE_CHECKBOX,
  },
  frameShape: {
    key: 'frameShape',
    label: 'frameShape',
    type: FILTER_TYPE_CHECKBOX,
  },
  frameMaterial: {
    key: 'frameMaterial',
    label: 'frameMaterial',
    type: FILTER_TYPE_CHECKBOX,
  },
  lensColor: {
    key: 'lensColor',
    label: 'lensColor',
    type: FILTER_TYPE_CHECKBOX,
  },
  lensMaterial: {
    key: 'lensMaterial',
    label: 'lensMaterial',
    type: FILTER_TYPE_CHECKBOX,
  },
  lensTreatment: {
    key: 'lensTreatment',
    label: 'lensTreatment',
    type: FILTER_TYPE_CHECKBOX,
  },
  polarizedLens: {
    key: 'polarizedLens',
    label: 'polarizedLens',
    type: FILTER_TYPE_FLAG,
  },
  vto: {
    key: 'vto',
    label: 'vto',
    type: FILTER_TYPE_FLAG,
  },
  roxable: {
    key: 'roxable',
    label: 'roxable',
    type: FILTER_TYPE_FLAG,
  },
  progressiveFriendly: {
    key: 'progressiveFriendly',
    label: 'progressiveFriendly',
    type: FILTER_TYPE_FLAG,
  },
  lensHeight: {
    key: 'lensHeight',
    label: 'lensHeight',
    type: FILTER_TYPE_RANGE,
    minKey: 'minLensHeight',
    maxKey: 'maxLensHeight',
    range: [
      {
        min: 22,
        max: 57,
        optionValues: ['22_57'],
      },
    ],
  },
  price: {
    key: 'price',
    label: 'price',
    type: FILTER_TYPE_PRICE,
    minKey: 'minPrice',
    maxKey: 'maxPrice',
    range: [
      {
        min: 51,
        max: 150,
        optionValues: ['51_100', '101_150'],
      },
      {
        min: 151,
        max: 250,
        optionValues: ['151_200', '201_250'],
      },
      {
        min: 251,
        max: 400,
        optionValues: ['251_300', '301_350', '351_400'],
      },
      {
        min: 401,
        max: 600,
        optionValues: ['401_450', '451_500', '501_550', '551_600'],
      },
    ],
  },
  customizable: {
    key: 'customizable',
    label: 'customizable',
    type: FILTER_TYPE_CHECKBOX,
  },
}
export type FrameAdvisorFilterKind = keyof typeof DEFAULT_FA_FILTERS
export type FrameAdvisorDefaultFilter = {
  key: string
  label: string
  type: FilterType
}
export type FrameAdvisorDefaultFilters = {
  [kind in FrameAdvisorFilterKind]: FrameAdvisorDefaultFilter
}
