import { graphqlTypes } from '@abstract/core'
import { useSelector, shallowEqual } from 'react-redux'

export const DEFAULT_STORE = '10131'
export const DEFAULT_STORETYPE = 'rayban'
export const DEFAULT_LANG = 'en-US'

export interface StoreIdentity {
  storeType: string
  storeId: string
  langCode: string
  basePath: string
}

const getWithFallback = (value: graphqlTypes.Maybe<string>, fallback: string) => {
  if (!value || value === 'undefined') return fallback
  return value
}

export const useStoreIndentity = () => {
  return useSelector(state => {
    const [, urlStoreType, urlStoreId, urlLangCode] = state.router.location.pathname.split('/')

    const storeType = getWithFallback(urlStoreType, DEFAULT_STORETYPE)
    const storeId = getWithFallback(urlStoreId, DEFAULT_STORE)
    const langCode = getWithFallback(urlLangCode, DEFAULT_LANG)

    return {
      storeType,
      storeId,
      langCode,
      basePath: `/${storeType}/${storeId}/${langCode}`,
    }
  }, shallowEqual)
}
