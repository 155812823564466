import { createGlobalStyle } from 'styled-components'
import { fonts } from './fonts'

export const GlobalStyle = createGlobalStyle`
  ${fonts}

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
  }

  body {
    overflow: hidden;
    font-family: ${({ theme }) => theme.fonts.primary};
    /* font-size: 0.727rem; */
    /* font-size: min(max(0.727rem, 4vw), 22px); */
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    color: ${({ theme }) => theme.colors.mineShaft};
    letter-spacing: normal;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  /* override erroneous antd computation with rem */
  a.ant-btn.ant-btn {
    line-height: 2.109rem;
  }

  .ant-modal-root {
    .ant-modal-body {
      padding: 1.636rem;
    }

    .ant-modal-content {
      border-radius: 0;
    }

    .ant-modal-close {
      top: 1.364rem;
      right: 1.364rem;
    }

    .ant-modal-close-x {
      width: 1.455rem;
      height: 1.455rem;
    }
  }

  .ant-select-dropdown {
    box-shadow: none !important;
    border: 1px solid #e2e2e2;
    border-radius: 0 !important;
  }

  .ant-select-dropdown-menu-item,
  .ant-select-dropdown-menu-item-selected {
    font-size: 0.591rem !important;
    font-weight: normal !important;
    background-color: white !important;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait),
    screen and (min-height: 768px) and (max-height: 1024px) and (orientation: landscape) {
    html {
      font-size: calc(16px + 6 * ((100vmin - 768px) / 256));
    }
  }
  @media screen and (min-width: 1024px) and (orientation: portrait), screen and (min-height: 1024px) and (orientation: landscape) {
    html {
      font-size: 22px;
    }
  }
`
