import { Dispatch } from 'react'
import { Point } from 'types/common'

export const SET_POS = 'keyboard/SET_POS'

export interface StorePositionAction {
  type: typeof SET_POS
  payload: Point
}

export const storePosition = (x: number, y: number) => (
  dispatch: Dispatch<StorePositionAction>
) => {
  dispatch({
    type: SET_POS,
    payload: { x, y },
  })
}

export type KeyboardActionTypes = StorePositionAction
